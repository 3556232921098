import styled from "styled-components";

export const Div = styled.div`
  padding: 0;
  margin: 0;
  display: inline-block;
  font-size: 1rem;
  position: relative;
`;

export const FlexRow = styled(Div)`
  flex: 1;
  display: flex;
  flex-direction: row;
`;

export const FlexColumn = styled(Div)`
  flex: 1;
  display: flex;
  flex-direction: column;
`;
