import React from "react";
import styled from "styled-components";
import { useLangData } from "../../hooks/useLangData";
import { COLORS } from "../../styledComponents/colors";
import { SCREEN_SIZES } from "../../styledComponents/screenSizes";

const Title = styled.h1`
  font-family: "GothamBold";
  color: ${COLORS.darkGrey};
  text-align: center;
  text-transform: uppercase;
  font-size: 1.375rem;
  margin-bottom: 5px;
  margin-top: 100px;
  padding: 0 15px;
`;

const Subtitle = styled.h3`
  font-family: "GothamBook";
  font-size: 0.9375rem;
  color: ${COLORS.grey};
  text-align: center;
  padding: 0 15px;
`;

const IconsContainer = styled.div`
  width: 100%;
  margin: 15px auto;
  margin-bottom: 90px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  padding: 0 100px;

  @media only screen and (max-width: ${SCREEN_SIZES.tablet}) {
    padding: 0 35px;
  }
  @media only screen and (max-width: ${SCREEN_SIZES.mobile}) {
    padding: 0 25px;
  }
`;

const Icon = styled.img`
  margin: 25px;
  height: 85px;

  @media only screen and (max-width: ${SCREEN_SIZES.laptop}) {
    height: 65px;
  }

  @media only screen and (max-width: ${SCREEN_SIZES.mobile}) {
    height: 45px;
    margin: 15px;
  }
`;

export const PartnersList = () => {
  const { servicesInfo } = useLangData(["servicesInfo"]);
  const { clientsAndPartners } = servicesInfo || {};

  return (
    <>
      <Title>{clientsAndPartners?.title}</Title>
      <Subtitle>{clientsAndPartners?.description}</Subtitle>

      <IconsContainer>
        {clientsAndPartners?.icons.map((item, index) => (
          <Icon src={item.imageUrl} alt={item.altText} key={index} />
        ))}
      </IconsContainer>
    </>
  );
};
