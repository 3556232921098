import styled from "styled-components";
import { Link } from "react-router-dom";
import { COLORS } from "./colors";
import { SCREEN_SIZES } from "./screenSizes";

export const ColumnList = styled.div`
  font-size: 0.6rem;
  color: ${COLORS.white};
  list-style-type: none;
  padding: 0;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 9px;
  font-family: "GothamBold";

  @media only screen and (max-width: ${SCREEN_SIZES.mobile}) {
    grid-template-columns: repeat(2, minmax(0, 1fr));
    margin: 0 -15px;
  }
`;

export const ColumnItem = styled(Link)`
  text-decoration: none;
  padding: 45px 50px 45px 38px;
  font-size: 1rem;
  background-color: ${COLORS.lighterBlue};
  flex: calc(90% / 3) 0 0;
  display: flex;
  flex-direction: column;
  transition: transform 0.5s ease;
  cursor: pointer;
  justify-content: center;

  &:hover {
    transform: scale(1.03);
  }

  @media only screen and (max-width: ${SCREEN_SIZES.laptop}) {
    padding: 35px 40px 35px 30px;
  }

  @media only screen and (max-width: ${SCREEN_SIZES.mobile}) {
    padding: 36px 17px;
  }
`;

export const ColumnTitle = styled.h4`
  text-transform: uppercase;
  font-size: 1rem;
  max-width: 14rem;
  position: relative;
  transition: transform 0.5s ease;

  ${ColumnItem}:hover & {
    transform: translateY(-15px);
  }

  @media only screen and (max-width: ${SCREEN_SIZES.mobile}) {
    font-size: 0.8rem;
    ${ColumnItem}:hover & {
      transform: none;
    }
  }
`;

export const ColumnLink = styled.span`
  text-decoration: none;
  text-transform: capitalize;
  color: ${COLORS.extraLighterGray};
  font-family: "GothamBook";
  padding-right: 40px;
  transform: translateY(100%);
  opacity: 0;
  transition: opacity 0.5s ease, transform 0.5s ease;
  padding-top: 10px;
  position: absolute;
  top: 100%;
  @media only screen and (max-width: ${SCREEN_SIZES.mobile}) {
    display: none;
    transition: none;
  }

  ${ColumnItem}:hover & {
    opacity: 1;
    transform: translateY(0);
  }
`;
