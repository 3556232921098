import { Link as RouterLink } from "react-router-dom";
import styled from "styled-components";

export const Link = styled(RouterLink)`
  text-decoration: none;
  opacity: ${(props) => (props.match ? 1 : 0.7)};
  ${(props) =>
    props.match ? "font-family: GothamBold; text-decoration: underline;" : ""};
  cursor: pointer;
  transition: opacity 0.3s ease;
  &:hover {
    opacity: 1;
  }
`;
