import { useContext } from "react";
import LanguageContext from "../contexts/languagesContext";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { usePathFormatting } from "../hooks/usePathFormatting";
import { Header, Div, FlexColumn, FlexRow } from "../styledComponents";
import { SCREEN_SIZES } from "../styledComponents/screenSizes";
import { CustomLink } from "./customLink";
import { Logo } from "./logo";
import { MenuList } from "./menuList";
import { NavLink } from "react-router-dom";

const Content = styled(Div)`
  font-size: 0.75rem;
  margin: 15px 0 0 0;
  display: none;
`;

const LogoContainer = styled(FlexColumn)`
  justify-content: right;
  align-items: flex-end;
  display: flex;
  z-index: 11;
  @media only screen and (max-width: ${SCREEN_SIZES.mobile}) {
    svg {
      height: 25px;
    }
  }

  @media only screen and (min-width: ${SCREEN_SIZES.large}) {
    svg {
      height: 45px;
    }
  }
`;

const MenuContainer = styled(FlexRow)`
  @media only screen and (max-width: ${SCREEN_SIZES.mobile}) {
    display: none;
  }
`;

export const HeaderSection = ({ color, opacity }) => {
  const languageCtx = useContext(LanguageContext);
  const { language } = languageCtx;

  const newPath = usePathFormatting();
  const { i18n } = useTranslation();

  //this function changes the i18n language and the language in the global state to the selected language
  const handleLanguageChange = (e) => {
    languageCtx.selectLanguage(e);
    i18n.changeLanguage(e);
  };

  return (
    <Header color={color} opacity={opacity}>
      <MenuContainer>
        <MenuList hasActiveStyles={true} />
      </MenuContainer>
      <LogoContainer>
        <NavLink to={`/${language}`}>
          <Logo />
        </NavLink>
        <Content>
          <CustomLink
            to={`/fr${newPath}`}
            onClick={() => handleLanguageChange("fr")}
          >
            FR
          </CustomLink>
          -
          <CustomLink
            to={`/en${newPath}`}
            onClick={() => handleLanguageChange("en")}
          >
            EN
          </CustomLink>
        </Content>
      </LogoContainer>
    </Header>
  );
};
