import React, { useState, useEffect } from "react";
import styled from "styled-components";
import DOMPurify from "dompurify";
import { fadeInAnimation, fadeInUpAnimation } from "../components/animation";
import { SCREEN_SIZES } from "./screenSizes";

const Wrapper = styled.span`
  text-transform: uppercase;
  font-size: 40px;
  margin-bottom: 15px;
  display: flex;
  flex-direction: column;

  @media only screen and (max-width: ${SCREEN_SIZES.laptop}) {
    font-size: 30px;
  }

  @media only screen and (max-width: ${SCREEN_SIZES.mobile}) {
    font-size: 8.7vw;
    font-family: "GothamBold";
    line-height: 1.1;
  }

  span {
    font-family: "GothamBold";
    display: inline-block;
    opacity: 0;
    animation-name: ${fadeInUpAnimation};
    animation-duration: 0.3s;
    animation-fill-mode: forwards;
    animation-iteration-count: 1;
    animation-timing-function: cubic-bezier(0.01, -0.01, 1, 1);

    @media only screen and (max-width: ${SCREEN_SIZES.mobile}) {
      animation-name: ${fadeInAnimation};
    }
  }

  @media only screen and (min-width: ${SCREEN_SIZES.mobile}) {
    ${(props) => getAnimations(props.textArraySize)};
  }
`;

const spanLoop = (i, totalNumber) =>
  `
  span:nth-child(${i + 1}) {
    animation-delay: ${`${(i * 0.5) / totalNumber}s`};
  }
`;

const getAnimations = (n) => {
  let str = "";
  for (let i = 0; i < n; i += 1) {
    str += spanLoop(i, n);
  }
  return str;
};

export const TextAnimation = ({ text }) => {
  //splits the title based on the line breaks existing when fetched
  const textArray = text?.split("<br/>");
  const [textArraySize, setTextArraySize] = useState();

  useEffect(() => {
    setTextArraySize(textArray?.length);
  }, [textArray?.length]);

  return (
    //the wrapper executes the animation, each line will be a separate nested <span> inside the wrapper having its corresponding delay
    <Wrapper textArraySize={textArraySize ? textArraySize : 1}>
      {textArray?.map((item, index) => (
        <span
          dangerouslySetInnerHTML={{
            __html: DOMPurify.sanitize(item),
          }}
          key={index}
        />
      ))}
    </Wrapper>
  );
};
