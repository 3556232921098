import styled from "styled-components";
import DOMPurify from "dompurify";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import { useContext } from "react";
import LanguageContext from "../../contexts/languagesContext";
import { FlexColumn, ButtonIconAnimationWhite } from "../../styledComponents";
import { getYoutubeURL } from "../../utils/videoURL";
import { isLatinCharacters } from "../../utils/isLatinCharacters";
import { SCREEN_SIZES } from "../../styledComponents/screenSizes";
import { COLORS } from "../../styledComponents/colors";

const PreviewContainer = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
`;

const PreviewImage = styled.img`
  position: absolute;
  min-width: 100%;
  min-height: 100%;
  max-width: initial;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  @media only screen and (max-width: ${SCREEN_SIZES.mobile}) {
    display: none;
  }
`;

const PreviewImageMobile = styled(PreviewImage)`
  display: none;
  @media only screen and (max-width: ${SCREEN_SIZES.mobile}) {
    display: block;
    width: 100%;
    height: auto;
  }
`;

const PreviewVideoContainer = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  aspect-ratio: 16 / 9;
  min-width: 115%;
  min-height: 115%;
  pointer-events: none;

  @media only screen and (max-width: ${SCREEN_SIZES.mobile}) {
    display: none;
  }
`;

const PreviewVideo = styled.iframe`
  position: absolute;
  width: 100%;
  height: 100%;
  border: none;
`;

const TextContainer = styled(FlexColumn)`
  color: ${COLORS.white};
  max-width: 80%;
  @media only screen and (max-width: ${SCREEN_SIZES.mobile}) {
    max-width: 70%;
  }
`;

const AwardsContainer = styled.div`
  display: flex;
  flex-direction: row;
  max-width: 800px;
  flex-wrap: wrap;
  margin-bottom: 50px;

  @media only screen and (max-width: ${SCREEN_SIZES.laptop}) {
    margin-bottom: 10px;
  }

  @media only screen and (max-width: ${SCREEN_SIZES.mobile}) {
    display: none;
  }
`;

const AwardImage = styled.img`
  display: inline-block;
  padding-right: 40px;
  padding-bottom: 20px;
  height: 85px;

  @media only screen and (max-width: ${SCREEN_SIZES.laptop}) {
    height: 70px;
  }
`;

const Title = styled.h2`
  font-family: "GothamBlack";
  font-size: 3.4rem;
  max-width: 40rem;
  line-height: 1;
  margin-bottom: 20px;
  text-transform: uppercase;

  ${({ isArabic }) =>
    isArabic &&
    `
    font-family: "DinArabicBold";
  `}

  @media only screen and (max-width: ${SCREEN_SIZES.laptop}) {
    font-size: 3rem;
  }

  @media only screen and (max-width: ${SCREEN_SIZES.mobile}) {
    font-size: 2rem;
    max-width: auto;
  }
`;

const Description = styled.p`
  font-size: 1.2rem;
  margin-bottom: 20px;
  max-width: 30rem;

  @media only screen and (max-width: ${SCREEN_SIZES.laptop}) {
    font-size: 1.1rem;
  }

  @media only screen and (max-width: ${SCREEN_SIZES.mobile}) {
    display: none;
  }
`;

export const PreviewItem = ({ item }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { language } = useContext(LanguageContext);

  const navigateToProjects = (project) => {
    navigate(`/${language}/work/${project}`);
  };

  return (
    <>
      <PreviewContainer>
        {item.mobileImage && (
          <PreviewImageMobile src={item.mobileImage} alt={item.title} />
        )}
        {item.preview.youtube ? (
          <PreviewVideoContainer>
            <PreviewVideo
              frameBorder="0"
              src={getYoutubeURL(item.preview.youtube)}
            ></PreviewVideo>
          </PreviewVideoContainer>
        ) : item.preview.sliderImage || item.preview.fallbackImage ? (
          <PreviewImage
            src={item.preview.sliderImage || item.preview.fallbackImage}
            alt={item.title}
          ></PreviewImage>
        ) : null}
      </PreviewContainer>
      <TextContainer>
        {item?.awards && item?.awards.length > 0 && (
          <AwardsContainer>
            {item.awards.map((award, index) => (
              <AwardImage
                key={index}
                src={award.imageUrl}
                alt={award.altText}
              ></AwardImage>
            ))}
          </AwardsContainer>
        )}
        <Title
          isArabic={!isLatinCharacters(item.preview.title || item.title)}
          dangerouslySetInnerHTML={{
            __html: DOMPurify.sanitize(item.preview.title || item.title),
          }}
        />
        <Description>{item.description}</Description>
        <div>
          <ButtonIconAnimationWhite
            onClick={() => navigateToProjects(item.slug)}
          >
            {t("projects.viewCaseStudy")}
          </ButtonIconAnimationWhite>
        </div>
      </TextContainer>
    </>
  );
};
