import React from "react";
import { useTranslation } from "react-i18next";
import { FooterSection } from "../../components/footerSection";
import { MetaDecorator } from "../../utils/metaDecorator";
import { LandingPreview } from "./landingPreview";
import { ProjectsList } from "./projectsList";

export const Projects = () => {
  const { t } = useTranslation();

  return (
    <>
      <MetaDecorator
        title="Spring - Projects"
        description={t("metaTags.projectsDescription")}
        type="website"
        alt="Spring projects"
      />
      <LandingPreview />
      <ProjectsList />
      <FooterSection />
    </>
  );
};
