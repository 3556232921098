import { useState, useEffect } from "react";
import styled from "styled-components";
import { MobileMenu } from "../components/mobileMenu";
import { COLORS } from "./colors";
import { SCREEN_SIZES } from "./screenSizes";

const COLOR_MAPPER = {
  light: { backgroundColor: COLORS.white, color: COLORS.darkBlue },
  dark: { backgroundColor: COLORS.darkBlue, color: COLORS.white },
  transparent: { backgroundColor: "transparent", color: COLORS.white },
};

const Wrapper = styled.header`
  z-index: 10;
  position: absolute;
  top: 0;
  width: 100%;
  height: auto;
  background-color: ${(props) =>
    COLOR_MAPPER[props.color]
      ? COLOR_MAPPER[props.color]?.backgroundColor
      : COLOR_MAPPER.dark.backgroundColor};
  color: ${(props) =>
    COLOR_MAPPER[props.color]
      ? COLOR_MAPPER[props.color]?.color
      : COLOR_MAPPER.dark.color};
  padding: 35px 80px;

  @media only screen and (max-width: ${SCREEN_SIZES.mobile}) {
    padding: 64px 25px;
    transition: color 0.2s ease 0.15s;
    color: ${({ isMenuOpen }) => isMenuOpen && COLOR_MAPPER.dark.color};
  }
`;

const HeaderComponent = styled.header`
  font-size: 20px;
  display: flex;
  flex-direction: row;
  margin: 0 auto;

  svg {
    transition: fill 0.2s ease 0.15s;
    fill: ${(props) =>
      COLOR_MAPPER[props.color]
        ? COLOR_MAPPER[props.color]?.color
        : COLOR_MAPPER.dark.color};
  }

  @media only screen and (max-width: ${SCREEN_SIZES.mobile}) {
    svg {
      fill: ${({ isMenuOpen }) => isMenuOpen && COLOR_MAPPER.dark.color};
    }
  }
`;

export const Header = ({ color, children }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  useEffect(() => {
    if (isMenuOpen) {
      document.body.classList.add("disableScroll");
    } else {
      document.body.classList.remove("disableScroll");
    }
  }, [isMenuOpen]);

  return (
    <Wrapper color={color} isMenuOpen={isMenuOpen}>
      <HeaderComponent isMenuOpen={isMenuOpen} color={color}>
        {children}
      </HeaderComponent>
      <MobileMenu
        color={color}
        isMenuOpen={isMenuOpen}
        onBurgerMenuClick={() => setIsMenuOpen(!isMenuOpen)}
      />
    </Wrapper>
  );
};
export const H2 = styled.h2`
  font-family: "GothamBlack";
  font-size: 1.8rem;

  @media only screen and (max-width: ${SCREEN_SIZES.laptop}) {
    line-height: 1;
  }

  @media only screen and (max-width: ${SCREEN_SIZES.mobile}) {
    font-size: 1.6rem;
  }
`;

export const H4 = styled.h2`
  font-family: "GothamBlack";
  font-size: 1.2rem;
  line-height: 1;
`;
