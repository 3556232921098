import React, { useState, useCallback, useEffect } from "react";
import styled, { css } from "styled-components";
import { useLangData } from "../../hooks/useLangData";
import { HeaderSection } from "../../components/headerSection";
import { ArrowDown } from "../../components/icons";
import { COLORS } from "../../styledComponents/colors";
import { TextAnimation } from "../../styledComponents/textAnimation";
import { VimeoVideo } from "../../components/video";
import { SCREEN_SIZES } from "../../styledComponents/screenSizes";
import { scaleAnimation } from "../../components/animation";

const Container = styled.div`
  position: relative;
  height: 100vh;
  min-height: calc(500px + 12rem);
  background-color: ${COLORS.darkBlue};
  font-family: "GothamBook";
  padding-top: calc(40vh + 22px);

  @media only screen and (max-width: ${SCREEN_SIZES.tablet}) {
    min-height: 100vh;
    height: auto;
    padding-top: calc(40vh + 80px);
  }
  @media only screen and (max-width: ${SCREEN_SIZES.mobile}) {
    padding-top: calc(25vh + 80px);
  }
`;

// contains the text at a higher z-index than the line-video animation so that they are always displayed
const TextContainer = styled.div`
  position: relative;

  padding-left: 0;
  left: 50%;
  transform: translateX(calc(-100% + 200px));
  width: 55vw;
  max-width: 65rem;
  min-height: 100%;
  opacity: 0;
  color: ${COLORS.white};
  padding-top: 110px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-bottom: 60px;
  transition: opacity 1s ease;

  ${({ show }) =>
    show &&
    `
  opacity:1;
`}

  @media only screen and (max-width: ${SCREEN_SIZES.tablet}) {
    padding-left: 50px;
  }

  @media only screen and (max-width: ${SCREEN_SIZES.mobile}) {
    padding: 0 25px;
    position: initial;
    transform: none;
    width: auto;
  }
`;

const Description = styled.h2`
  margin-left: 15px;
  vertical-align: middle;
  align-self: center;
  font-size: 1rem;
  flex: 1;
  display: inline-block;

  @media only screen and (max-width: ${SCREEN_SIZES.laptop}) {
    font-size: 0.9rem;
  }
`;

const IconContainer = styled.button`
  align-items: center;
  flex: initial;
  opacity: 0.7;
  transition: opacity 0.3s ease;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  &:hover {
    opacity: 1;
  }

  @media only screen and (max-width: ${SCREEN_SIZES.laptop}) {
    > svg {
      width: 1.5rem;
      height: 1.5rem;
    }
  }

  @media only screen and (max-width: ${SCREEN_SIZES.mobile}) {
    margin: 50px 0 25px 0;
  }
`;

const lineCSS = css`
  position: absolute;
  top: 40vh;
  left: calc(50% - 100px);
  height: 17px;
  width: 0;
  transition: width 0.7s ease, height 0.7s ease, left 0.7s ease, top 0.7s ease,
    right 0.7s ease, bottom 0.7s ease;

  @media only screen and (max-width: ${SCREEN_SIZES.mobile}) {
    display: none;
  }

  @media only screen and (min-width: ${SCREEN_SIZES.large}) {
    left: calc(50% - 150px);
  }
`;

const drawLineCSS = css`
  width: 35%;

  @media only screen and (max-width: ${SCREEN_SIZES.mobile}) {
    width: 60%;
  }
`;

const Line = styled.div`
  ${lineCSS}
  overflow: hidden;
  background-color: ${COLORS.white};

  ${({ drawLine }) => drawLine && drawLineCSS}
  ${({ enlargedLine }) =>
    enlargedLine &&
    `
    background-color: transparent;
    opacity: 0.8;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
 
  `}

 
  animation-name: ${({ animate }) => (animate ? scaleAnimation : "")};
  animation-duration: 1s;
  animation-timing-function: linear;

  @media only screen and (max-width: ${SCREEN_SIZES.mobile}) {
    animation-name: "";
  }
`;

const HoverableOpenContainer = styled.div`
  ${lineCSS}
  ${({ drawLine }) => drawLine && drawLineCSS}

  @media only screen and (max-width: ${SCREEN_SIZES.mobile}) {
    display: none;
  }
`;

//the container centers the transition of the iframe
const VideoContainer = styled.div`
  aspect-ratio: 16 / 9;
  min-width: 115vw;
  min-height: 115vh;
  transform: translate(-50%, -50%);
  position: absolute;
  top: 50%;
  left: 50%;
  pointer-events: none;
  border-width: 0;
  opacity: 0;

  ${({ showPreview }) =>
    showPreview &&
    `
    opacity: 0.5; 
    `}

  ${({ showVideoSnippet }) =>
    showVideoSnippet &&
    `
    opacity: 1;
    `}
    transition: opacity 0.3s ease;
`;

const HoverableCloseContainer = styled.div`
  width: 50%;
  height: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

export const Landing = () => {
  const { purpose } = useLangData(["purpose"]);

  const [drawLine, setDrawLine] = useState(false);
  const [showVideoSnippet, setShowVideoSnippet] = useState(false);
  const [enlargedLine, setEnlargedLine] = useState(false);
  const [animateLine, setAnimateLine] = useState(false);
  const [animateTimeout, setAnimateTimeout] = useState(undefined);
  const [showText, setShowText] = useState(false);

  //  useCallback used to prevent re-running a function unnecessarily
  const shrinkLine = useCallback(() => {
    setEnlargedLine(false);
  }, []);

  //  when the line is enlarged, displays the video
  const showVideo = useCallback(() => {
    setEnlargedLine(true);
    setShowVideoSnippet(true);
  }, []);

  //  when the line finished the transition, hides the video
  const hideVideo = useCallback(() => {
    //this function is called when the line is enlarged or shrunk
    //this condition sets showVideoSnippet state to false only if the line is shrunk
    if (!enlargedLine) {
      setShowVideoSnippet(false);
    }
  }, [enlargedLine]);

  useEffect(() => {
    setTimeout(() => {
      setDrawLine(true);
      setTimeout(() => {
        setShowText(true);
      }, 1500);
    }, 1000);
  }, []);

  const scroll = () => {
    window.scrollTo({ top: window.innerHeight, behavior: "smooth" });
  };

  const animateMouseOverLine = () => {
    if (!animateTimeout && !enlargedLine) {
      setAnimateLine(true);
      setAnimateTimeout(
        setTimeout(() => {
          setAnimateLine(false);
          clearTimeout(animateTimeout);
          setAnimateTimeout(undefined);
        }, 3000)
      );
    }
  };

  return (
    <Container onMouseMove={animateMouseOverLine}>
      <Line
        drawLine={drawLine}
        enlargedLine={enlargedLine}
        onTransitionEnd={hideVideo}
        animate={animateLine}
      >
        {purpose?.vimeo && (
          <VideoContainer
            showVideoSnippet={showVideoSnippet}
            showPreview={animateLine}
          >
            <VimeoVideo videoId={purpose?.vimeo} isTrailer={false} />
          </VideoContainer>
        )}
      </Line>
      {showText && (
        <TextContainer show={purpose?.title}>
          {/* this component controls the text and its relevant animations */}
          <TextAnimation text={purpose?.title} />
          <IconContainer onClick={scroll}>
            <ArrowDown width="36" height="36" />
            <Description>{purpose?.description}</Description>
          </IconContainer>
        </TextContainer>
      )}
      {showVideoSnippet ? (
        <HoverableCloseContainer
          onMouseLeave={shrinkLine}
        ></HoverableCloseContainer>
      ) : (
        <HoverableOpenContainer
          drawLine={drawLine}
          onMouseOver={purpose?.vimeo && showVideo}
        ></HoverableOpenContainer>
      )}
      <HeaderSection color="transparent" />
    </Container>
  );
};
