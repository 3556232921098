import styled from "styled-components";
import DOMPurify from "dompurify";
import { MenuList } from "./menuList";
import { FlexRow } from "../styledComponents";
import {
  FacebookIcon,
  YoutubeIcon,
  TwitterIcon,
  LinkedinIcon,
} from "../components/icons";
import { SCREEN_SIZES } from "../styledComponents/screenSizes";
import { useLangData } from "../hooks/useLangData";
import { COLORS } from "../styledComponents/colors";

const Menu = styled.div`
  display: none;

  @media only screen and (max-width: ${SCREEN_SIZES.mobile}) {
    background: ${COLORS.darkerBlue};
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 100vh;
    height: max-content;
    position: fixed;
    z-index: 10;
    left: 0;
    top: 0;
    right: 0;
    transform: translateX(-100%);
    transition: transform 0.5s ease;

    ${({ isMenuOpen }) =>
      isMenuOpen &&
      `
        transform: translateX(0);
  `}
  }
`;

const MenuWrapper = styled.div`
  padding-top: 130px;
  padding-left: 25px;
  display: flex;
  flex-direction: column;
`;

const BurgerMenu = styled.button`
  display: none;

  @media only screen and (max-width: ${SCREEN_SIZES.mobile}) {
    position: absolute;
    top: 75px;
    left: 25px;
    z-index: 11;
    width: 25px;
    height: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    div {
      width: 25px;
      height: 2px;
      background-color: ${({ color, isMenuOpen }) =>
        color === "light" && !isMenuOpen ? COLORS.darkBlue : COLORS.white};
      transition: transform 0.2s ease, background-color 0.1s ease 0.1s;
      transform-origin: 1px;

      ${({ isMenuOpen }) =>
        isMenuOpen &&
        `
      &:nth-child(1){
        transform: rotate(45deg) translateY(-6px);
      }

      &:nth-child(2){
       transform: rotate(-45deg) translateY(6px);
      }
      `}
    }
  }
`;

const Footer = styled.div`
  padding: 30px 35px;
`;

const AnchorIcon = styled.a`
  align-items: center;
  display: flex;
  margin: 0 15px 0 0;

  > svg {
    width: 25px;
    height: 25px;
  }
`;

const CopyrightDisclaimer = styled.span`
  margin-top: 15px;
  display: inline-block;
  width: 100%;
  flex: 1;
  color: ${COLORS.lightGrey};
  margin-top: 16px;
  text-transform: uppercase;
  text-align: left;
  font-size: 0.8rem;
  display: inherit;
  max-width: 15rem;
`;

const ContactContainer = styled.div`
  flex-direction: column;
  flex: 1;
  display: flex;
`;

const Title = styled.h2`
  font-family: "GothamBook";
  text-transform: uppercase;
  line-height: 1;
  flex: 1;
  font-size: 1rem;
`;

const Contact = styled.div`
  flex: 1;
  flex-grow: 0;
  align-self: flex-end;

  > span {
    color: ${COLORS.grey};
    font-size: 0.9rem;
    display: inline-block;
    width: 100%;
  }

  > a {
    text-decoration: none;
    font-size: 1.1rem;
  }

  align-self: flex-start;

  > span {
    margin-top: 15px;
  }
`;

const Separator = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${COLORS.grey};
  margin: 30px 0;
`;

export const MobileMenu = ({ color, isMenuOpen, onBurgerMenuClick }) => {
  const { contact } = useLangData(["contact"]);
  const { links, copyrightDisclaimer } = contact || {};

  return (
    <>
      <BurgerMenu
        isMenuOpen={isMenuOpen}
        onClick={() => onBurgerMenuClick()}
        color={color}
      >
        <div />
        <div />
      </BurgerMenu>
      <Menu isMenuOpen={isMenuOpen}>
        <MenuWrapper>
          <MenuList hasActiveStyles={true} />
        </MenuWrapper>
        <Footer>
          <ContactContainer>
            <Title
              dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(contact?.title),
              }}
            ></Title>
            {contact?.contacts?.[0] && (
              <Contact>
                <span>{contact.contacts[0].label}</span>
                <a href={`mailto:${contact.contacts[0].email}`}>
                  <b>{contact.contacts[0].email}</b>
                </a>
              </Contact>
            )}
          </ContactContainer>
          <Separator />
          <FlexRow>
            <AnchorIcon href={links?.facebook} target="_blank">
              <FacebookIcon />
            </AnchorIcon>
            <AnchorIcon href={links?.youtube} target="_blank">
              <YoutubeIcon />
            </AnchorIcon>
            <AnchorIcon href={links?.twitter} target="_blank">
              <TwitterIcon />
            </AnchorIcon>
            <AnchorIcon href={links?.linkedin} target="_blank">
              <LinkedinIcon />
            </AnchorIcon>
          </FlexRow>
          <FlexRow>
            <CopyrightDisclaimer>{copyrightDisclaimer}</CopyrightDisclaimer>
          </FlexRow>
        </Footer>
      </Menu>
    </>
  );
};
