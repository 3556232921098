import React from "react";
import Helmet from "react-helmet";

export const MetaDecorator = ({ title, type, description, image, alt }) => {
  return (
    <Helmet>
      <title> Spring - {title}</title>
      <meta property="og:title" content={title} />
      <meta property="og:type" content={type} />
      <meta property="og:description" content={description} />
      <meta
        property="og:image"
        content={image ? `%PUBLIC_URL%${image}` : `%PUBLIC_URL%/dummy.png`}
      />
      <meta property="og:url" content={window.location.href} />
      <meta name="twitter:card" content="summary_large_image" />
      <meta property="og:site_name" content="Spring Communication" />
      <meta name="twitter:image:alt" content={alt} />
    </Helmet>
  );
};
