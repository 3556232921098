import styled from "styled-components";
import { COLORS } from "./colors";
import { SCREEN_SIZES } from "./screenSizes";

export const List = styled.ul`
  font-size: 0.6rem;
  color: ${COLORS.white};
  list-style-type: none;
  padding: 35px 0;
  display: flex;
  flex-wrap: wrap;

  @media only screen and (max-width: ${SCREEN_SIZES.mobile}) {
    padding-bottom: 0;
  }
`;

export const ListItem = styled.li`
  font-size: 0.9rem;
  flex: 50% 0 0;
  position: relative;
  padding-bottom: 35px;
  opacity: ${({ dim, highlight }) => (!dim ? 1 : highlight ? 1 : 0.5)};
  transition: opacity 0.3s ease;

  &:nth-child(2n) {
    padding-left: 10px;
  }
  &:nth-child(2n + 1) {
    padding-right: 10px;
  }

  &:nth-child(n + 3):after {
    content: "";
    background-color: ${COLORS.darkBlue};
    width: 25px;
    height: 1px;
    position: absolute;
    left: 0;
    top: -15px;
  }

  &:nth-child(2n):after {
    left: 10px;
  }
`;

export const ListTitle = styled.h3`
  font-family: "GothamBold";
  padding-bottom: 11px;
  font-size: 1rem;
`;
