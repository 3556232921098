import { useContext } from "react";
import { Routes, Route, Outlet, Navigate } from "react-router-dom";
import LanguageContext from "./contexts/languagesContext";
import { Homepage } from "./pages/homepage";
import { Projects } from "./pages/projects";
import { ProjectDetails } from "./pages/projectDetails";
import { ProjectPreview } from "./pages/projectPreview";
import { Services } from "./pages/services";
import { Team } from "./pages/team";
import { NotFound } from "./pages/notFound";

const App = () => {
  const context = useContext(LanguageContext);
  const lang = context.language;

  const routes = (
    <>
      <Route path="notfound" element={<NotFound />} />
      <Route path="preview/:projectId" element={<ProjectPreview />} />
      <Route path="work/:projectId" element={<ProjectDetails />} />
      <Route path="work" element={<Projects />} />
      <Route path="services/:serviceId" element={<Services />} />
      <Route path="services" element={<Services />} />
      <Route path="team" element={<Team />} />
      <Route path="" element={<Homepage />} />
    </>
  );

  return (
    <div className="App">
      <Routes>
        <Route path={`${lang}/`} element={<Outlet />}>
          {routes}
        </Route>
        <Route path="*" element={<Navigate to={`/${lang}`} />} />
      </Routes>
    </div>
  );
};

export default App;
