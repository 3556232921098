import { useState, useEffect } from "react";
import styled from "styled-components";
import { useLangData } from "../../hooks/useLangData";
import { HeaderSection } from "../../components/headerSection";
import { Carousel } from "./carousel";
import { Section } from "../../styledComponents";
import { COLORS } from "../../styledComponents/colors";
import { SCREEN_SIZES } from "../../styledComponents/screenSizes";
//import rightArrow from "../../assets/icons/rightArrowProjects.svg";
//import leftArrow from "../../assets/icons/leftArrowProjects.svg";

const Container = styled(Section)`
  background: ${COLORS.darkBlue};
  height: 100vh;
  width: 100%;
  position: relative;
  overflow: hidden;
  padding: 0;
  color: ${COLORS.white};

  @media only screen and (max-width: ${SCREEN_SIZES.mobile}) {
    height: 65vh;
  }
`;

const Index = styled.span`
  z-index: 2;
  position: absolute;
  bottom: 30px;
  right: 50px;
  font-size: 1.3rem;
  font-family: "GothamBold";

  @media only screen and (max-width: ${SCREEN_SIZES.mobile}) {
    right: 15px;
  }
`;
/*
const Button = styled.button`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  opacity: 0.8;
  transition: opacity 0.3s ease;
  width: 25px;
  height: 25px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100% auto;

  &:hover {
    opacity: 1;
  }

  @media only screen and (max-width: ${SCREEN_SIZES.mobile}) {
    width: 20px;
    height: 20px;
    opacity: 1;
  }

  @media only screen and (min-width: ${SCREEN_SIZES.large}) {
    width: 50px;
    height: 50px;
  }
`;

const PreviousButton = styled(Button)`
  left: 50px;
  background-image: url(${leftArrow});

  @media only screen and (max-width: ${SCREEN_SIZES.mobile}) {
    left: 15px;
  }

  @media only screen and (min-width: ${SCREEN_SIZES.large}) {
    left: 100px;
  }
`;

const NextButton = styled(Button)`
  right: 50px;
  background-image: url(${rightArrow});

  @media only screen and (max-width: ${SCREEN_SIZES.mobile}) {
    right: 15px;
  }

  @media only screen and (min-width: ${SCREEN_SIZES.large}) {
    right: 100px;
  }
`;
*/
export const LandingPreview = () => {
  const { projects: allProjects } = useLangData(["projects"]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [projects, setProjects] = useState([]);

  useEffect(() => {
    if (allProjects) {
      setProjects(allProjects?.filter((project) => project.slider));
    }
  }, [allProjects]);

  const numberOfItems = projects?.length;
  const updateIndex = (newIndex) => {
    setCurrentIndex(newIndex);
  };
  return (
    <>
      <HeaderSection color="transparent" />
      <Container>
        {numberOfItems > 0 && (
          <Carousel data={projects} updateIndex={updateIndex} />
        )}

        {numberOfItems > 0 && (
          <Index>
            {numberOfItems && `${currentIndex + 1} / ${numberOfItems}`}
          </Index>
        )}
      </Container>
    </>
  );
};
