import { useEffect, useState } from "react";
import styled from "styled-components";
import DOMPurify from "dompurify";
import { useTranslation } from "react-i18next";
import { useLangData } from "../hooks/useLangData";
import {
  FlexRow,
  Section,
  H2,
  H4,
  FlexColumn,
  Anchor,
} from "../styledComponents";
import { COLORS } from "../styledComponents/colors";
import { MenuList } from "./menuList";
import {
  FacebookIcon,
  YoutubeIcon,
  TwitterIcon,
  LinkedinIcon,
} from "../components/icons";
import { SCREEN_SIZES } from "../styledComponents/screenSizes";

const Wrapper = styled(Section)`
  padding: 60px 100px 20px 100px;
  background-color: ${COLORS.darkBlue};

  @media only screen and (max-width: ${SCREEN_SIZES.tablet}) {
    padding: 60px 35px 20px 35px;
  }
  @media only screen and (max-width: ${SCREEN_SIZES.mobile}) {
    padding: 50px 25px 25px 25px;
  }
`;

const LocationContainer = styled.div`
  margin: 0 auto;
  color: ${COLORS.white};
  display: flex;
  flex-direction: column;
`;

const Title = styled(H2)`
  font-family: "GothamBook";
  text-transform: uppercase;
  line-height: 1;
  font-size: 2.3rem;
  flex-grow: 0;
  flex-basis: 33rem;
  flex-shrink: 0;

  @media only screen and (max-width: ${SCREEN_SIZES.laptop}) {
    flex-basis: 26rem;
    font-size: 1.8rem;
  }

  @media only screen and (max-width: ${SCREEN_SIZES.tablet}) {
    flex: 1;
  }
  @media only screen and (max-width: ${SCREEN_SIZES.mobile}) {
    font-size: 1.5rem;
  }
`;

const Contact = styled.div`
  flex: 1;
  flex-grow: 0;
  align-self: flex-end;

  > span {
    color: ${COLORS.grey};
    font-size: 0.9rem;
    display: inline-block;
    width: 100%;
  }

  > a {
    text-decoration: none;
    font-size: 1.1rem;

    @media only screen and (max-width: ${SCREEN_SIZES.laptop}) {
      font-size: 0.8rem;
    }
  }

  @media only screen and (max-width: ${SCREEN_SIZES.tablet}) {
    align-self: flex-start;

    > span {
      margin-top: 15px;
    }
  }
`;

const ContactContainer = styled(FlexRow)`
  justify-content: space-between;
  padding-right: 150px;

  @media only screen and (max-width: ${SCREEN_SIZES.laptop}) {
    padding-right: 0px;
  }

  @media only screen and (max-width: ${SCREEN_SIZES.tablet}) {
    flex-direction: column;
  }
`;

const LocationRow = styled(FlexRow)`
  justify-content: space-between;

  @media only screen and (max-width: ${SCREEN_SIZES.tablet}) {
    flex-wrap: wrap;
  }

  @media only screen and (max-width: ${SCREEN_SIZES.mobile}) {
    display: inline-block;
  }
`;
const LocationTitle = styled(H4)`
  padding-top: 90px;
  padding-bottom: 30px;

  @media only screen and (max-width: ${SCREEN_SIZES.mobile}) {
    display: none;
  }
`;

const LocationWrapper = styled(FlexColumn)`
  flex-basis: 24.5%;
  flex-grow: 0;
  flex-shrink: 0;
  position: relative;
  overflow: hidden;
  height: 300px;

  @media only screen and (max-width: ${SCREEN_SIZES.tablet}) {
    flex-basis: 49.5%;
  }

  @media only screen and (max-width: ${SCREEN_SIZES.mobile}) {
    &:nth-child(1) {
      margin-top: 40px;
    }
    margin-top: 20px;

    height: 150px;
  }
`;

const LocationItem = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  height: 300px;

  @media only screen and (max-width: ${SCREEN_SIZES.mobile}) {
    display: flex;
    flex-direction: row;
    height: 100%;
  }
`;

const LocationContent = styled.div`
  @media only screen and (max-width: ${SCREEN_SIZES.mobile}) {
    padding-left: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
`;

const LocationImageContainer = styled.div`
  width: 100%;
  height: 128px;
  position: relative;
  overflow: hidden;
  transition: height 0.3s ease;

  @media only screen and (max-width: ${SCREEN_SIZES.mobile}) {
    height: 100%;
  }

  img {
    min-width: 100%;
    min-height: 100%;
    width: auto;
    height: auto;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    transition: min-width 0.3s ease, min-height 0.3s ease;
  }

  ${LocationWrapper}:hover & {
    height: 150px;

    img {
      min-width: 110%;
      min-height: 110%;
    }
  }
`;

const LocationName = styled.p`
  font-size: 1.5rem;
  padding-top: 20px;

  @media only screen and (max-width: ${SCREEN_SIZES.laptop}) {
    font-size: 1rem;
  }

  @media only screen and (max-width: ${SCREEN_SIZES.mobile}) {
    padding-top: 0px;
    font-size: 0.8rem;
  }
`;

const LocationTime = styled.p`
  font-size: 14px;
  padding-bottom: 10px;

  @keyframes fadeInOut {
    0% {
      opacity: 0;
    }
    50% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }

  > b > span {
    animation: fadeInOut 2s steps(1) infinite;
  }
`;

const LocationDetails = styled.div`
  opacity: 0;
  font-size: 13px;
  transform: translateY(50px);
  transition: transform 0.3s ease, opacity 0.3s ease;

  > p:nth-child(1) {
    color: ${COLORS.grey};
    max-width: 60%;
    max-width: 230px;
    padding-bottom: 10px;
  }

  ${LocationWrapper}:hover & {
    opacity: 1;
    transform: translateY(0);
  }

  @media only screen and (max-width: ${SCREEN_SIZES.tablet}) {
    opacity: 1;
    transform: translateY(0);
  }
`;

const MenuWrapper = styled(Section)`
  background-color: ${COLORS.darkerBlue};
  padding: 60px 100px;

  @media only screen and (max-width: ${SCREEN_SIZES.laptop}) {
    padding: 30px 100px;
  }
  @media only screen and (max-width: ${SCREEN_SIZES.tablet}) {
    padding: 30px 35px;
  }
`;

const MenuContainer = styled.div`
  color: ${COLORS.white};
  margin: 0 auto;
`;

const CopyrightDisclaimer = styled.span`
  margin-top: 15px;
  display: inline-block;
  width: 100%;
  text-align: right;
  flex: 1;
  color: ${COLORS.white};
  opacity: 0.65;
  font-size: 13px;
  margin-top: 16px;
  text-transform: uppercase;
  @media only screen and (max-width: ${SCREEN_SIZES.laptop}) {
    font-size: 11px;
  }

  @media only screen and (max-width: ${SCREEN_SIZES.mobile}) {
    text-align: left;
    font-size: 0.8rem;
    display: inherit;
    max-width: 15rem;
  }
`;

const AnchorIcon = styled(Anchor)`
  /* text-decoration: underline; */
  align-items: center;
  display: flex;
  margin: 0 30px 25px 0;

  @media only screen and (max-width: ${SCREEN_SIZES.laptop}) {
    margin: 0 25px 0 0;
    > svg {
      width: 25px;
      height: 25px;
    }
  }

  @media only screen and (max-width: ${SCREEN_SIZES.mobile}) {
    margin: 0 15px 0 0;
  }
`;

const FooterMenu = styled(FlexRow)`
  @media only screen and (max-width: ${SCREEN_SIZES.mobile}) {
    display: none;
  }
`;

export const FooterSection = () => {
  const { t } = useTranslation();

  const { contact } = useLangData(["contact"]);
  const { offices, links, copyrightDisclaimer } = contact || {};

  const [currentDate, setCurrentDate] = useState(new Date());
  const [minuteInterval, setMinuteInterval] = useState(new Date());
  const [minuteTimeout, setMinuteTimeout] = useState(new Date());

  useEffect(() => {
    const date = new Date();
    const remainingToMinute = 60 - date.getSeconds();
    setMinuteTimeout(
      setTimeout(() => {
        setCurrentDate(new Date());
        createMinuteInterval();
      }, [remainingToMinute * 1000])
    );

    return () => {
      if (minuteInterval) {
        clearInterval(minuteInterval);
      }
      if (minuteTimeout) {
        clearTimeout(minuteTimeout);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const calculateTime = (date, offset) => {
    var utc = date.getTime() + date.getTimezoneOffset() * 60000;
    var newDate = new Date(utc + 3600000 * +offset);

    return newDate
      .toLocaleString("en-US", {
        hour: "2-digit",
        minute: "2-digit",
        hour12: true,
      })
      .toLocaleLowerCase()
      .replace(":", "<span>:</span>");
  };

  const createMinuteInterval = () => {
    setMinuteInterval(
      setInterval(() => {
        setCurrentDate(new Date());
      }, [60 * 1000])
    );
  };

  return (
    <>
      <Wrapper>
        <LocationContainer>
          <ContactContainer>
            <Title
              dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(contact?.title),
              }}
            ></Title>
            {contact?.contacts.map((item, index) => (
              <Contact key={index}>
                <span>{item.label}</span>
                <a href={`mailto:${item.email}`}>
                  <b>{item.email}</b>
                </a>
              </Contact>
            ))}
          </ContactContainer>
          <FlexColumn>
            <LocationTitle>{t("footer.offices")}</LocationTitle>
            <LocationRow>
              {offices?.map((item, index) => (
                <LocationWrapper key={index}>
                  <LocationItem>
                    <LocationImageContainer>
                      <img alt={item.city} src={item.image}></img>
                    </LocationImageContainer>
                    <LocationContent>
                      <LocationName>
                        <b>{item.city}</b>
                      </LocationName>
                      <LocationTime
                        dangerouslySetInnerHTML={{
                          __html: DOMPurify.sanitize(
                            `<b>${calculateTime(currentDate, item.offset)}</b>`
                          ),
                        }}
                      ></LocationTime>
                      <LocationDetails>
                        <p>{item.address}</p>
                        <a href={`mailto:${item.email}`}>
                          <b>{item.email}</b>
                        </a>
                      </LocationDetails>
                    </LocationContent>
                  </LocationItem>
                </LocationWrapper>
              ))}
            </LocationRow>
          </FlexColumn>
        </LocationContainer>
      </Wrapper>
      <MenuWrapper>
        <MenuContainer>
          <FlexRow>
            <AnchorIcon href={links?.facebook} target="_blank">
              <FacebookIcon />
            </AnchorIcon>
            <AnchorIcon href={links?.youtube} target="_blank">
              <YoutubeIcon />
            </AnchorIcon>
            <AnchorIcon href={links?.twitter} target="_blank">
              <TwitterIcon />
            </AnchorIcon>
            <AnchorIcon href={links?.linkedin} target="_blank">
              <LinkedinIcon />
            </AnchorIcon>
          </FlexRow>
          <FlexRow>
            <FooterMenu>
              <MenuList hasActiveStyles={false} />
            </FooterMenu>
            <CopyrightDisclaimer>{copyrightDisclaimer}</CopyrightDisclaimer>
          </FlexRow>
        </MenuContainer>
      </MenuWrapper>
    </>
  );
};
