import { PREVIEW_URL } from "./constants";

export const getProjectPreviewData = (lang, id) => {
  return fetch(`${PREVIEW_URL}/${id}`, {
    headers: { "Accept-Language": lang },
  })
    .then((response) => response.json())
    .then((project) => {
      if (project.title?.en) {
        project.title = project.title.en;
      }
      if (project.title?.fr) {
        project.title = project.title.fr;
      }
      if (project.description?.en) {
        project.description = project.description.en;
      }
      if (project.description?.fr) {
        project.description = project.description.fr;
      }

      if (project.detailedDescription?.en) {
        project.detailedDescription = project.detailedDescription.en;
      }
      if (project.detailedDescription?.fr) {
        project.detailedDescription = project.detailedDescription.fr;
      }
      project.components?.map((item) => {
        if (item.details?.title?.en) {
          item.details.title = item.details.title.en;
        }
        if (item.details?.title?.fr) {
          item.details.title = item.details.title.fr;
        }
        if (item.details?.description?.en) {
          item.details.description = item.details.description.en;
        }
        if (item.details?.description?.fr) {
          item.details.description = item.details.description.fr;
        }
        return item;
      });

      return project;
    });
};
