import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { Item } from "../projectDetails/item";
import { ProjectOverview } from "../projectDetails/projectOverview";
import { getProjectPreviewData } from "../../services/getProjectPreviewData";
import LanguageContext from "../../contexts/languagesContext";

export const ProjectPreview = () => {
  const { language } = useContext(LanguageContext);
  const [project, setProject] = useState({});
  const { projectId } = useParams();

  useEffect(() => {
    getProjectPreviewData(language, projectId).then((resp) => {
      setProject(resp);
    });
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <br />
      <br />
      <br />
      <ProjectOverview project={project} />
      {project?.components?.map((item, index) => (
        <Item
          item={{ type: item.type, ...item.details }}
          key={index}
          firstElement={index === 0}
        />
      ))}
      <br />
    </>
  );
};
