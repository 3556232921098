import DOMPurify from "dompurify";
import React from "react";
import styled from "styled-components";
import { SCREEN_SIZES } from "../../styledComponents/screenSizes";

const Container = styled.div`
  display: flex;
  justify-content: ${({ direction }) => {
    return direction === "right"
      ? "flex-end"
      : direction === "center"
      ? "center"
      : "flex-start";
  }};
`;

const Content = styled.div`
  display: inline-block;
  max-width: 50%;

  @media only screen and (max-width: ${SCREEN_SIZES.tablet}) {
    max-width: 70%;
  }

  @media only screen and (max-width: ${SCREEN_SIZES.mobile}) {
    max-width: 100%;
  }
`;

const Title = styled.h3`
  font-size: 1.3rem;
  font-family: "GothamBold";
  margin-bottom: 20px;
  display: inline-block;
`;

const Description = styled.p`
  font-size: 1rem;
  display: inline-block;
`;

export const Text = ({ title, description, direction }) => {
  return (
    <Container direction={direction}>
      <Content>
        {title && <Title>{title}</Title>}
        {description && (
          <Description
            dangerouslySetInnerHTML={{
              __html: DOMPurify.sanitize(description),
            }}
          ></Description>
        )}
      </Content>
    </Container>
  );
};
