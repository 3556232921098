import { useTranslation } from "react-i18next";
import { MetaDecorator } from "../../utils/metaDecorator";
import { FooterSection } from "../../components/footerSection";
import { Approach } from "./approach";
import { Services } from "./services";
import { ProjectsList } from "./projectsList";
import { Team } from "./team";
import { Landing } from "./landing";

export const Homepage = () => {
  const { t } = useTranslation();
  return (
    <>
      <MetaDecorator
        title="Spring Communications"
        type="website"
        description={t("metaTags.companyDescription")}
        alt={t("metaTags.homepageAlt")}
      />
      <Landing />
      <Services />
      <Approach />
      <ProjectsList />
      <Team />
      <FooterSection />
    </>
  );
};
