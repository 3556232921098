import React from "react";
import styled from "styled-components";
import { COLORS } from "../styledComponents/colors";

const Container = styled.div`
  display: flex;
  align-items: center;
  text-align: center;
  font-family: "GothamBold";
  background: ${COLORS.darkBlue};
  min-height: 62vh;
  color: ${COLORS.white};
`;

export const TitleSection = (props) => {
  return <Container>{props.children}</Container>;
};
