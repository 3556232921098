import { keyframes } from "styled-components";

//the animation that controls how the text enters
export const fadeInUpAnimation = keyframes`
//starts invisible, blurred and pushed downwards
0% {
   transform: translateY(100px);
 
}

30% {
 opacity:0;
}
//the animation ends visible and in its position
100% {
  opacity: 1;
  transform: translateY(0px);
  }
`;

export const fadeInAnimation = keyframes`
//starts invisible, blurred and pushed downwards
 
//the animation ends visible and in its position
100% {
  opacity: 1;
   }
`;

export const pulseAnimation = keyframes`
  0% { transform: scale(1); }
  30% { transform: scale(1); }
  40% { transform: scale(1.08); }
  50% { transform: scale(1); }
  60% { transform: scale(1); }
  70% { transform: scale(1.05); }
  80% { transform: scale(1); }
  100% { transform: scale(1); }
`;

export const scaleAnimation = keyframes`
  0% { transform: scale(1); }
  20% { transform: scale(0.999); }
  70% { transform: scale(1.02); }
  100% { transform: scale(1); }
`;
