import { Trailer } from "./trailer";
import { Mobiles } from "./mobiles";
import { LandscapeMobiles } from "./landscapeMobiles";
import { Icons } from "./icons";
import { ColumnImages } from "./columnImages";
import { Text } from "./text";
import { ImageText } from "./imageText";

export const ComponentMapper = {
  trailer: Trailer,
  mobiles: Mobiles,
  landscapeMobiles: LandscapeMobiles,
  icons: Icons,
  images: ColumnImages,
  text: Text,
  imageText: ImageText,
};
