import React from "react";
import styled from "styled-components";
import { FlexColumn, FlexRow } from "../../styledComponents";
import { isLatinCharacters } from "../../utils/isLatinCharacters";
import { COLORS } from "../../styledComponents/colors";
import { SCREEN_SIZES } from "../../styledComponents/screenSizes";

const Wrapper = styled.div`
  padding: 0 120px 60px 120px;

  @media only screen and (max-width: ${SCREEN_SIZES.mobile}) {
    padding: 0 25px;
  }
`;

const Section = styled(FlexRow)`
  margin: 0 auto;
  justify-content: space-between;
  color: ${COLORS.black};
  font-size: 1.2rem;
  > div {
    flex: 1;
    max-width: 600px;
    @media only screen and (max-width: ${SCREEN_SIZES.mobile}) {
      font-size: 1rem;
    }

    &:nth-child(1) {
      padding-right: 100px;
      max-width: 700px;

      @media only screen and (max-width: ${SCREEN_SIZES.mobile}) {
        padding-right: 25px;
      }
    }
  }

  @media only screen and (max-width: ${SCREEN_SIZES.tablet}) {
    padding: 0 35px 60px 35px;
    flex-direction: column;
  }

  @media only screen and (max-width: ${SCREEN_SIZES.mobile}) {
    padding: 0 0 30px 0;
  }
`;

const Title = styled.h1`
  font-size: 3rem;
  font-family: "GothamBlack";
  line-height: 1.1;
  text-transform: uppercase;

  ${({ isArabic }) =>
    isArabic &&
    `
  font-family: "DinArabicBold";
  margin-bottom: 15px;
  `}

  @media only screen and (max-width: ${SCREEN_SIZES.mobile}) {
    font-size: 1.7rem;
    line-height: 1;
    margin-bottom: 10px;
  }
`;

const Description = styled.p`
  opacity: 0.7;
  font-size: 1rem;
  opacity: 0.6;
  @media only screen and (max-width: ${SCREEN_SIZES.tablet}) {
    margin-bottom: 15px;
  }
`;

export const ProjectOverview = ({ project }) => {
  return (
    <Wrapper>
      <Section>
        <FlexColumn>
          <Title isArabic={!isLatinCharacters(project.title)}>
            {project.title}
          </Title>
          <Description>{project.description}</Description>
        </FlexColumn>
        <div>{project.detailedDescription}</div>
      </Section>
    </Wrapper>
  );
};
