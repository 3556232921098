import DOMPurify from "dompurify";
import React from "react";
import styled from "styled-components";
import { SCREEN_SIZES } from "../../styledComponents/screenSizes";

const Content = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  @media only screen and (max-width: ${SCREEN_SIZES.mobile}) {
    flex-direction: column;
  }
`;

const Title = styled.h3`
  font-size: 1.3rem;
  font-family: "GothamBold";
  margin-bottom: 20px;
  margin-left: 50px;

  @media only screen and (max-width: ${SCREEN_SIZES.mobile}) {
    margin: 25px 0 0 0;
  }
`;

const Description = styled.p`
  font-size: 1rem;
  margin-left: 50px;

  @media only screen and (max-width: ${SCREEN_SIZES.mobile}) {
    margin-left: 0;
    margin-top: 5px;
  }
`;

const TextContainer = styled.div`
  flex: 1;
`;

const Image = styled.img`
  flex: 1;
`;

export const ImageText = ({ title, description, image }) => {
  return (
    <Content>
      <Image src={image?.path} alt={image?.alt} />
      <TextContainer>
        {title && <Title>{title}</Title>}
        {description && (
          <Description
            dangerouslySetInnerHTML={{
              __html: DOMPurify.sanitize(description),
            }}
          ></Description>
        )}
      </TextContainer>
    </Content>
  );
};
