import { matchPath, useResolvedPath, useLocation } from "react-router-dom";
import { Link } from "../styledComponents";

export const CustomLink = ({ children, to, ...props }) => {
  const { pathname } = useLocation();
  const resolved = useResolvedPath(to);
  const match = matchPath(resolved.pathname + "/*", pathname);

  return (
    <Link match={match} to={to} {...props}>
      {children}
    </Link>
  );
};
