import styled from "styled-components";
import { COLORS } from "./colors";

export const SubTitle = styled.h2`
  padding: 10px 0 5px 0;
  font-size: 1.1rem;
  text-transform: uppercase;
  font-family: "GothamBold";
  color: ${({ color }) => color || COLORS.darkBlue};
`;
