import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { YoutubeVideo, VimeoVideo } from "../../components/video";

const VideoContainer = styled.div`
  aspect-ratio: 16 / 9;
  width: 100%;
  border-width: 0;
  position: relative;
  overflow: hidden;
`;

const ImageOverlay = styled.img`
  position: absolute;
  min-width: 100%;
  min-height: 100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 1;
  transition: opacity 0.5s ease 1.5s;
  ${({ hideImage }) => hideImage && "opacity: 0;"}
`;

export const Trailer = ({ youtube, vimeo, image, isPreviewComponent }) => {
  const [hideImage, setHideImage] = useState(false);
  const [removeImage, setRemoveImage] = useState(false);
  useEffect(() => {
    if (!isPreviewComponent) {
      setHideImage(true);
      setTimeout(() => {
        setRemoveImage(true);
      }, 2000);
    }
  }, [isPreviewComponent]);

  return (
    <VideoContainer>
      {vimeo ? (
        <VimeoVideo videoId={vimeo} isTrailer={true} />
      ) : youtube ? (
        <YoutubeVideo videoId={youtube} isTrailer={true} />
      ) : null}
      {image && !removeImage && (
        <ImageOverlay src={image} hideImage={hideImage} />
      )}
    </VideoContainer>
  );
};
