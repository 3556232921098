import React from "react";
import styled from "styled-components";
import DOMPurify from "dompurify";
import { useTranslation } from "react-i18next";
import { useLangData } from "../../hooks/useLangData";
import { COLORS } from "../../styledComponents/colors";
import { SCREEN_SIZES } from "../../styledComponents/screenSizes";

const Container = styled.div`
  min-height: 50vh;
  text-align: left;
  background-color: ${COLORS.white};
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  max-width: 1500px;
  margin: 0 auto;
  padding: 0 60px 30px 60px;
  color: ${COLORS.darkBlue};

  @media only screen and (max-width: ${SCREEN_SIZES.mobile}) {
    padding: 0 30px 30px 30px;
  }
`;

const Item = styled.div`
  flex-basis: 25%;
  overflow: hidden;
  box-sizing: border-box;
  padding: 25px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  @media only screen and (max-width: ${SCREEN_SIZES.tablet}) {
    padding: 25px 0;
    flex-basis: calc(100% / 3);
  }

  @media only screen and (max-width: ${SCREEN_SIZES.mobile}) {
    flex-basis: 50%;
    font-size: 0.8rem;
  }
`;

const Image = styled.img`
  border-radius: 50%;
  width: 250px;
  height: 250px;
  max-width: 250px;
  max-height: 250px;
  margin-bottom: 30px;

  @media only screen and (max-width: ${SCREEN_SIZES.laptop}) {
    width: 180px;
    height: 180px;
    max-width: 180px;
    max-height: 180px;
  }

  @media only screen and (max-width: ${SCREEN_SIZES.tablet}) {
    width: 120px;
    height: 120px;
    max-width: 120px;
    max-height: 120px;
    margin-bottom: 15px;
  }
`;

const Title = styled.span`
  font-family: "GothamBold";
  font-size: 1.2rem;

  @media only screen and (max-width: ${SCREEN_SIZES.mobile}) {
    font-size: 0.8rem;
  }
`;

const Header = styled.h3`
  text-transform: uppercase;
  font-size: 1.2rem;
  padding: 50px 25px 25px 25px;
  text-align: center;
  color: ${COLORS.darkBlue};
`;

export const PeopleList = () => {
  const { team } = useLangData(["team"]);
  const { t } = useTranslation();

  return (
    <>
      <Header
        dangerouslySetInnerHTML={{
          __html: DOMPurify.sanitize(t("theTeamHeader")),
        }}
      ></Header>
      <Container>
        {team?.people?.map((item, index) => {
          return (
            <Item item={item} index={index} key={index}>
              <Image
                src={item.photoUrl}
                alt={`${item.fullName} - ${item.title}`}
              />
              <Title>{item.fullName}</Title>
              {item.title}
            </Item>
          );
        })}
      </Container>
    </>
  );
};
