import React, { useContext, useEffect, useRef, useState } from "react";
import {
  UNSAFE_NavigationContext as NavigationContext,
  useParams,
} from "react-router-dom";

import styled from "styled-components";
import { useLangData } from "../../hooks/useLangData";
import { PageContent } from "./pageContent";

const Container = styled.div`
  height: 100vh;
  overflow: hidden;
  pointer-events: none;
`;

export const ProjectDetails = () => {
  const { navigator } = useContext(NavigationContext);
  const { projects } = useLangData(["projects"]);
  const { projectId } = useParams();
  const [projectSlug, setProjectSlug] = useState();
  const [isTransitioning, setIsTransitioning] = useState(false);
  const [yOffset, setYOffset] = useState(0);
  const ref = useRef(null);

  useEffect(() => {
    const listener = ({ location, action }) => {
      const { pathname } = location;
      const projectId = pathname.substring(pathname.lastIndexOf("/") + 1);

      if (action === "POP" || projectSlug === projectId) {
        setProjectSlug(projectId);
        setTimeout(() => {
          window.scrollTo({ top: 0, behavior: "smooth" });
        }, 200);
      } else {
        setYOffset(Math.round(ref?.current?.getBoundingClientRect().top));
        setIsTransitioning(true);
        window.scrollTo(0, 0);
        setTimeout(() => {
          setProjectSlug(projectId);
          setIsTransitioning(false);
        }, [1300]);
      }
    };

    const unlisten = navigator.listen(listener);
    return unlisten;
  }, [navigator, projectSlug]);

  useEffect(() => {
    setProjectSlug(projectId);
    // eslint-disable-next-line
  }, []);

  if (!isTransitioning) {
    return <PageContent projectId={projectSlug} ref={ref} />;
  }

  const projectIndex = projects?.findIndex((item) => item.slug === projectSlug);
  const nextProject = projects?.[projectIndex + 1] || projects?.[0] || {};
  const nextProjectSlug = nextProject.slug;

  return (
    <Container>
      <PageContent projectId={projectSlug} fadeOut yOffset={yOffset} />
      <PageContent projectId={nextProjectSlug} scrollUp yOffset={yOffset} />
    </Container>
  );
};
