import styled from "styled-components";

const Overlay = styled.div`
  width: 100%;
  height: 100%;

  ${({ isTrailer }) => !isTrailer && "pointer-events: none;"}
`;

export const VimeoVideo = ({ videoId, isTrailer }) => {
  return (
    <Overlay isTrailer={isTrailer}>
      <iframe
        src={`https://player.vimeo.com/video/${videoId}?autoplay=1&loop=1&muted=1&background=${
          isTrailer ? 0 : 1
        }`}
        width="100%"
        height="100%"
        frameBorder="0"
        allow="autoplay"
        title="Vimeo Video"
      ></iframe>
    </Overlay>
  );
};

export const YoutubeVideo = ({ videoId, isTrailer }) => {
  return (
    <Overlay isTrailer={isTrailer}>
      <iframe
        type="text/html"
        width="100%"
        height="100%"
        src={`https://www.youtube.com/embed/${videoId}?enablejsapi=1&vq=large&controls=${
          isTrailer ? 1 : 0
        }&modestbranding=1&autoplay=1&loop=1&mute=1&playlist=${videoId}&disablekb=${
          isTrailer ? 0 : 1
        }`}
        frameBorder="0"
        title="Youtube Video"
      ></iframe>
    </Overlay>
  );
};
