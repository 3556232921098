import { useContext } from "react";
import { useTranslation } from "react-i18next";
import LanguageContext from "../contexts/languagesContext";
import { MenuItem } from "../styledComponents";

export const MenuList = ({ hasActiveStyles }) => {
  const { t } = useTranslation();
  const context = useContext(LanguageContext);
  const language = context.language;

  return (
    <>
      <MenuItem to={`/${language}`} hasActiveStyles={hasActiveStyles} end>
        {t("header.homepage")}
      </MenuItem>
      <MenuItem to={`/${language}/team`} hasActiveStyles={hasActiveStyles} end>
        {t("header.about")}
      </MenuItem>
      <MenuItem to={`/${language}/services`} hasActiveStyles={hasActiveStyles}>
        {t("header.services")}{" "}
      </MenuItem>
      <MenuItem to={`/${language}/work`} hasActiveStyles={hasActiveStyles}>
        {t("header.work")}
      </MenuItem>
    </>
  );
};
