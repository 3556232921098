import React, { useEffect } from "react";
import styled from "styled-components";
import { useParams } from "react-router-dom";
import { useLangData } from "../../hooks/useLangData";
import { COLORS } from "../../styledComponents/colors";
import { Item } from "./item";
import { SCREEN_SIZES } from "../../styledComponents/screenSizes";

const ServiceItems = styled.ul`
  text-align: left;
  list-style: none;
  background-color: ${COLORS.white};
  width: 100%;
  li {
    padding: 100px 240px;
  }

  li:nth-child(2n) {
    background: ${COLORS.lighterGrey};
  }
  //this sets the backgrouond color to white for all odd list items
  li:nth-child(2n + 1) {
    background: ${COLORS.white};
  }

  @media only screen and (max-width: ${SCREEN_SIZES.tablet}) {
    li {
      padding: 50px 50px;
    }
  }
`;

export const ServicesList = () => {
  const { servicesInfo } = useLangData(["servicesInfo"]);
  const { servicesList: services } = servicesInfo || {};
  const { serviceId } = useParams("serviceId");

  useEffect(() => {
    if (!serviceId) {
      window.scrollTo({ top: 0 });
    }
  }, [serviceId]);

  return (
    <ServiceItems>
      {services?.map((item, index) => {
        return (
          <Item
            item={item}
            index={index}
            key={index}
            scrollTo={serviceId === item.slug}
          />
        );
      })}
    </ServiceItems>
  );
};
