import styled from "styled-components";
import { PreviewItem } from "./previewItem";
import { Autoplay, Navigation } from "swiper";
import { SCREEN_SIZES } from "../../styledComponents/screenSizes";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

const Container = styled.div`
  min-height: 100vh;
  justify-content: center;
  align-items: center;
  padding-top: 100px;
  padding-bottom: 30px;
  display: flex;
  flex: 1;
  flex-basis: 100vw;
  position: relative;
  width: 100vw;
  overflow: hidden;

  @media only screen and (max-width: ${SCREEN_SIZES.mobile}) {
    min-height: 65vh;
  }
`;

export const Carousel = ({ data, updateIndex }) => {
  // const [currentIndex, setCurrentIndex] = useState();
  /*
  const scrollToElement = () => {
    if (itemElements?.current.length > 0) {
      if (cloneIndex !== undefined) {
        wrapper.current.scrollTo({
          left:
            cloneIndex === 0
              ? window.innerWidth
              : data.length * window.innerWidth,
          behavior: "instant",
        });
      }

      if (currentIndex === 0 && direction === FORWARD) {
        setCloneIndex(0);

        wrapper.current.scrollTo({
          left: (data.length + 1) * window.innerWidth,
          behavior: "smooth",
        });
      } else {
        if (currentIndex === data.length - 1 && direction === BACKWARD) {
          setCloneIndex(data.length - 1);

          wrapper.current.scrollTo({
            left: 0,
            behavior: "smooth",
          });
        } else {
          setCloneIndex(undefined);
          wrapper.current.scrollTo({
            left: (currentIndex + 1) * window.innerWidth,
            behavior: "smooth",
          });
        }
      }
    }
  };
  useEffect(() => {
    scrollToElement();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentIndex, itemElements, width]);

  useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  });
*/

  return (
    <Swiper
      loop={true}
      autoplay={{
        delay: 8000,
        disableOnInteraction: true,
      }}
      slidesPerView={1}
      navigation={true}
      modules={[Autoplay, Navigation]}
      onSlideChange={({ realIndex }) => {
        updateIndex(realIndex);
      }}
    >
      {data.map((item, index) => (
        <SwiperSlide key={index}>
          <Container>
            <PreviewItem item={item} />
          </Container>
        </SwiperSlide>
      ))}
    </Swiper>
  );
};
