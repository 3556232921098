export const FacebookIcon = ({ color, width, height }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill={color || "white"}
    width={width || "32.574"}
    height={height || "32.444"}
    viewBox="0 0 32.574 32.444"
  >
    <g id="Page-1" transform="translate(-38.5 -46.1)">
      <g id="Artboard" transform="translate(38.5 46.1)">
        <g
          id="f_logo_RGB-White_1024"
          data-name="f logo RGB-White 1024"
          transform="translate(0 0)"
        >
          <path
            id="Fill-1"
            d="M71.074,62.413A16.287,16.287,0,1,0,52.246,78.544V67.13h-4.13V62.413h4.13v-3.6c0-4.091,2.437-6.358,6.15-6.358a25.464,25.464,0,0,1,3.648.313v4.013H60a2.365,2.365,0,0,0-2.658,2.554V62.4h4.521l-.717,4.717H57.354V78.531a16.3,16.3,0,0,0,13.72-16.118"
            transform="translate(-38.5 -46.1)"
            fillRule="evenodd"
          />
        </g>
      </g>
    </g>
  </svg>
);

export const YoutubeIcon = ({ color, width, height }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill={color || "white"}
    width={width || "34.107"}
    height={height || "23.912"}
    viewBox="0 0 34.107 23.912"
  >
    <path
      id="Path_34240"
      data-name="Path 34240"
      d="M755.543-622.982a4.337,4.337,0,0,0-3.028-3.028c-2.657-.711-13.315-.711-13.315-.711s-10.659,0-13.315.711a4.337,4.337,0,0,0-3.028,3.028,44.571,44.571,0,0,0-.711,8.218,44.572,44.572,0,0,0,.711,8.218,4.337,4.337,0,0,0,3.028,3.028c2.657.711,13.315.711,13.315.711s10.658,0,13.315-.711a4.337,4.337,0,0,0,3.028-3.028,44.391,44.391,0,0,0,.711-8.218A44.754,44.754,0,0,0,755.543-622.982ZM735.8-609.6v-10.226l8.867,5.128Z"
      transform="translate(-722.146 626.72)"
    />
  </svg>
);

export const TwitterIcon = ({ color, width, height }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill={color || "white"}
    width={width || "26.197"}
    height={height || "21.396"}
    viewBox="0 0 26.197 21.396"
  >
    <path
      id="Path_34234"
      data-name="Path 34234"
      d="M1074.752-462.371a5.85,5.85,0,0,1,.028.7c0,7.16-5.4,15.382-15.312,15.382a15.092,15.092,0,0,1-8.241-2.433,9.529,9.529,0,0,0,1.281.084,10.765,10.765,0,0,0,6.682-2.321,5.435,5.435,0,0,1-5.039-3.748,5.492,5.492,0,0,0,1,.112,4.711,4.711,0,0,0,1.42-.2,5.425,5.425,0,0,1-4.315-5.314v-.056a5.5,5.5,0,0,0,2.422.671,5.422,5.422,0,0,1-2.394-4.5,5.637,5.637,0,0,1,.724-2.713,15.394,15.394,0,0,0,11.108,5.65,4.519,4.519,0,0,1-.139-1.231,5.389,5.389,0,0,1,5.373-5.4,5.281,5.281,0,0,1,3.925,1.706,10.621,10.621,0,0,0,3.424-1.314,5.38,5.38,0,0,1-2.366,2.993,11.212,11.212,0,0,0,3.09-.839A9.361,9.361,0,0,1,1074.752-462.371Z"
      transform="translate(-1051.228 467.685)"
    />
  </svg>
);

export const LinkedinIcon = ({ color, width, height }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill={color || "white"}
    width={width || "27.668"}
    height={height || "25.405"}
    viewBox="0 0 27.668 25.405"
  >
    <path
      id="Path_34239"
      data-name="Path 34239"
      d="M523.539-232.072h-5.393v-17.259h5.393ZM520.7-251.49h-.039a2.994,2.994,0,0,1-3.217-2.991,3.025,3.025,0,0,1,3.294-3,3.008,3.008,0,0,1,3.252,2.992A3.018,3.018,0,0,1,520.7-251.49Zm24.412,19.418H539V-241c0-2.338-.955-3.933-3.06-3.933a3.089,3.089,0,0,0-2.92,2.114,3.977,3.977,0,0,0-.132,1.411v9.339h-6.058s.079-15.82,0-17.259h6.058v2.709c.358-1.184,2.293-2.875,5.384-2.875,3.832,0,6.843,2.483,6.843,7.828Z"
      transform="translate(-517.448 257.477)"
    />
  </svg>
);

export const ArrowDown = ({ color, width, height }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill={color || "white"}
    width={width || "40"}
    height={height || "40"}
    viewBox="0 0 40 40"
  >
    <g
      id="Group_62135"
      data-name="Group 62135"
      transform="translate(-276 -925)"
    >
      <g
        id="Icon_feather-arrow-down-circle"
        data-name="Icon feather-arrow-down-circle"
        transform="translate(278 929.105)"
      >
        <path
          id="Path_34077"
          data-name="Path 34077"
          d="M12,18l6,6,6-6"
          fill="none"
          stroke="#fff"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="3"
        />
        <path
          id="Path_34078"
          data-name="Path 34078"
          d="M18,12V28.21"
          transform="translate(0 -4.21)"
          fill="none"
          stroke="#fff"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="3"
        />
      </g>
      <g
        id="Rectangle_160"
        data-name="Rectangle 160"
        transform="translate(278 927)"
        fill="none"
        stroke="#fff"
        strokeWidth="2"
      >
        <rect width="36" height="36" stroke="none" />
        <rect x="-1" y="-1" width="38" height="38" fill="none" />
      </g>
    </g>
  </svg>
);

export const DisplayArrowRight = ({ color, width, height }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill={color || "white"}
    width={width || "13.949"}
    height={height || "11.674"}
    viewBox="0 0 13.949 11.674"
  >
    <g
      id="Icon_feather-arrow-down-circle"
      data-name="Icon feather-arrow-down-circle"
      transform="translate(1 10.26) rotate(-90)"
    >
      <path
        id="Path_34077"
        data-name="Path 34077"
        d="M0,0,4.423,4.423,8.845,0"
        transform="translate(0 7.526)"
        fill="none"
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
      <path
        id="Path_34078"
        data-name="Path 34078"
        d="M0,0V11.949"
        transform="translate(4.423)"
        fill="none"
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
    </g>
  </svg>
);
export const ButtonArrowRight = ({ color, width, height }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill={color || "white"}
    width={width || "23.309"}
    height={height || "16.511"}
    viewBox="0 0 23.309 16.511"
  >
    <g
      id="Icon_feather-arrow-right"
      data-name="Icon feather-arrow-right"
      transform="translate(1.5 2.121)"
    >
      <path
        id="Path_34071"
        data-name="Path 34071"
        d="M0,0H20.309"
        transform="translate(0 6.134)"
        fill="none"
        stroke="#16639b"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="3"
      />
      <path
        id="Path_34072"
        data-name="Path 34072"
        d="M0,0,6.134,6.134,0,12.268"
        transform="translate(14.175)"
        fill="none"
        stroke="#16639b"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="3"
      />
    </g>
  </svg>
);
