import i18n from "i18next";
import HttpApi from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";

const validLanguages = ["en", "fr"];

//if the url contains a valid language, this function returns that language
const getTrueLang = () => {
  const currentLang = window.location.pathname.split("/")[1];
  return validLanguages.includes(currentLang) ? currentLang : null;
};

i18n
  .use(HttpApi)
  .use(initReactI18next)
  .use(LanguageDetector) //if the language isn't set in the url, we're detecting the browser language
  .init({
    lng: getTrueLang(),
    fallbackLng: validLanguages,
    debug: false,
    interpolation: {
      escapeValue: false,
    },
    detection: {
      caches: [],
      load: "languageOnly",
      react: {
        bindI18n: "languageChanged",
        useSuspense: true,
      },
    },
  });
export default i18n;
