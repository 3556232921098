import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useLangData } from "../../hooks/useLangData";
import {
  Section,
  H2,
  ButtonIconAnimation,
  ButtonIconAnimationWhite,
} from "../../styledComponents";
import { isLatinCharacters } from "../../utils/isLatinCharacters";
import { getYoutubeURL, getVimeoURL } from "../../utils/videoURL";
import { COLORS } from "../../styledComponents/colors";
import { useEffect, useState } from "react";
import { SCREEN_SIZES } from "../../styledComponents/screenSizes";

const Wrapper = styled(Section)`
  padding: 30px 60px 60px 60px;

  @media only screen and (max-width: ${SCREEN_SIZES.tablet}) {
    padding: 10px 10px 25px 10px;
  }
`;

const Container = styled.div`
  margin: 0 auto;

  @media only screen and (min-width: ${SCREEN_SIZES.large}) {
    max-width: 2000px;
  }
`;

const Row = styled.div`
  width: 100%;
  display: inline-flex;
  align-items: center;
  margin: 30px 0;
  height: 21vw;
  padding: 50px;
  position: relative;
  background: black;
  overflow: hidden;
  transition: height 0.3s ease, margin 0.3s ease;
  &:hover {
    height: calc(21vw + 50px);
    margin: 5px 0;
  }

  @media only screen and (max-width: ${SCREEN_SIZES.laptop}) {
    margin: 20px 0;
    &:hover {
      height: calc(21vw + 30px);
    }
  }

  @media only screen and (max-width: ${SCREEN_SIZES.tablet}) {
    margin: 10px 0 0 0;
    padding: 25px;
    height: 200px;
    &:hover {
      height: 200px;
      margin: 10px 0 0 0;
    }
  }

  @media only screen and (max-width: ${SCREEN_SIZES.mobile}) {
    margin: 10px 0 0 0;
    height: 280px;
    &:hover {
      height: 280px;
      margin: 10px 0 0 0;
    }
  }

  @media only screen and (min-width: ${SCREEN_SIZES.large}) {
    padding: 100px;
  }
`;

const Content = styled.div`
  position: relative;
  max-width: 23rem;
  color: ${COLORS.white};

  > h2 {
    text-transform: uppercase;
  }
`;

const PreviewVideoContainer = styled.div`
  min-height: 100%;
  min-width: 100%;
  aspect-ratio: 16 / 9;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  pointer-events: none;

  @media only screen and (max-width: ${SCREEN_SIZES.tablet}) {
    display: none;
  }
`;

const PreviewVideo = styled.iframe`
  position: absolute;
  width: 100%;
  height: 100%;
  border: none;
`;

const PreviewImage = styled.img`
  position: absolute;
  width: 100%;
  height: auto;
  max-width: initial;
  left: 0;
  transition: opacity 0.5s ease;
  opacity: 1;
  @media only screen and (max-width: ${SCREEN_SIZES.tablet}) {
    height: 100%;
    width: auto;
    opacity: 0.6;
    left: 50%;
    transform: translateX(-50%);
  }
`;

const PreviewVideoImage = styled(PreviewImage)`
  ${Row}:hover & {
    opacity: 0;
    @media only screen and (max-width: ${SCREEN_SIZES.tablet}) {
      opacity: 0.6;
    }
  }
`;

const Description = styled.p`
  margin-top: 5px;
  font-size: 1rem;

  @media only screen and (max-width: ${SCREEN_SIZES.tablet}) {
    display: none;
  }
`;

const ViewButton = styled(ButtonIconAnimation)`
  margin: 25px auto 0 auto;
  display: block;
`;

const ProjectButtonContainer = styled.div`
  opacity: 0;
  max-height: 0;
  overflow: hidden;
  transform: translateY(30px);
  transition: transform 0.3s ease, opacity 0.5s ease, max-height 0.3s ease;

  ${Row}:hover & {
    opacity: 1;
    transform: translateY(0);
    max-height: calc(78px + 1rem);
  }

  > button {
    margin-top: 30px;
  }

  @media only screen and (max-width: ${SCREEN_SIZES.tablet}) {
    opacity: 1;
    max-height: initial;
    transform: translateY(0);

    > button {
      margin-top: 15px;
    }
  }
`;

const Title = styled(H2)`
  ${({ isArabic }) =>
    isArabic &&
    `
    font-family: "DinArabicBold";
    margin-bottom: 12px;
`}
`;

export const ProjectsList = () => {
  const { projects: allProjects } = useLangData(["projects"]);

  const navigate = useNavigate();
  const [projects, setProjects] = useState([]);

  const { t } = useTranslation();

  useEffect(() => {
    if (allProjects) {
      setProjects(allProjects?.filter((project) => project.highlighted));
    }
  }, [allProjects]);

  const navigateToProjects = (project) => {
    navigate(`work/${project}`);
  };

  return (
    <Wrapper>
      <Container>
        {projects?.map((item) => (
          <Row key={item.slug}>
            {item.preview.vimeo ? (
              <>
                <PreviewVideoContainer>
                  <PreviewVideo
                    frameBorder="0"
                    src={getVimeoURL(item.preview.vimeo)}
                  ></PreviewVideo>
                </PreviewVideoContainer>
                <PreviewVideoImage
                  src={item.preview.homepageImage}
                  alt={item.title}
                ></PreviewVideoImage>
              </>
            ) : item.preview.youtube ? (
              <>
                <PreviewVideoContainer>
                  <PreviewVideo
                    frameBorder="0"
                    src={getYoutubeURL(item.preview.youtube)}
                  ></PreviewVideo>
                </PreviewVideoContainer>
                <PreviewVideoImage
                  src={item.preview.homepageImage}
                  alt={item.title}
                ></PreviewVideoImage>
              </>
            ) : item.preview.homepageImage ? (
              <PreviewImage
                src={item.preview.homepageImage}
                alt={item.title}
              ></PreviewImage>
            ) : null}
            <Content>
              <Title isArabic={!isLatinCharacters(item.title)}>
                {item.title}
              </Title>
              <Description>{item.description}</Description>
              <ProjectButtonContainer>
                <ButtonIconAnimationWhite
                  onClick={() => navigateToProjects(item.slug)}
                >
                  {t("projects.viewCaseStudy")}
                </ButtonIconAnimationWhite>
              </ProjectButtonContainer>
            </Content>
          </Row>
        ))}
        <ViewButton onClick={() => navigateToProjects("")}>
          {t("projects.viewAllCaseStudies")}
        </ViewButton>
      </Container>
    </Wrapper>
  );
};
