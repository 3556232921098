import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { useLangData } from "../../hooks/useLangData";
import { COLORS } from "../../styledComponents/colors";
import { SCREEN_SIZES } from "../../styledComponents/screenSizes";
import { isLatinCharacters } from "../../utils/isLatinCharacters";

const Wrapper = styled.div`
  padding: 50px 120px;
  @media only screen and (max-width: ${SCREEN_SIZES.tablet}) {
    grid-template-columns: 1fr 1fr;
    padding: 50px;
  }

  @media only screen and (max-width: ${SCREEN_SIZES.mobile}) {
    padding: 10px;
  }
`;

const Container = styled.div`
  margin: 0 auto;
  font-family: "GothamBlack";
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  margin-top: 4px;
  background-color: ${COLORS.white};
  max-width: 2000px;
  @media only screen and (max-width: ${SCREEN_SIZES.tablet}) {
    grid-template-columns: 1fr 1fr;
  }

  @media only screen and (max-width: ${SCREEN_SIZES.mobile}) {
    grid-template-columns: 1fr;
  }
`;

const ProjectCard = styled(Link)`
  padding: 8px;
  margin-bottom: 20px;
  width: 100%;
  cursor: pointer;

  @media only screen and (max-width: ${SCREEN_SIZES.mobile}) {
    padding: 0 0 10px 0;
  }
`;

const ImageContainer = styled.div`
  overflow: hidden;
  width: 100%;
  aspect-ratio: 9 / 6;
  position: relative;
`;

const ProjectImage = styled.img`
  min-width: 100%;
  min-height: 100%;
  max-width: initial;
  height: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: transform 0.3s ease;

  &:hover {
    transform: translate(-50%, -50%) scale(1.05);
  }
`;

const ProjectTitle = styled.h1`
  color: ${COLORS.darkerBlue};
  text-transform: capitalize;
  font-family: "GothamBook";
  text-align: left;
  font-size: 1.3rem;
  margin-top: 13px;

  ${({ isArabic }) =>
    isArabic &&
    `
  font-family: "DinArabic";
  `}

  @media only screen and (max-width: ${SCREEN_SIZES.laptop}) {
    font-size: 1.1rem;
  }

  @media only screen and (max-width: ${SCREEN_SIZES.mobile}) {
    font-size: 1.1rem;
  }
`;

export const ProjectsList = () => {
  const { projects } = useLangData(["projects"]);

  useEffect(() => {
    window.scrollTo({ top: 0 });
  }, []);

  return (
    <Wrapper>
      <Container>
        {projects?.map((project, index) => (
          <ProjectCard key={index} to={project.slug}>
            <ImageContainer>
              <ProjectImage
                src={project.preview.fallbackImage}
                alt={project.title}
              ></ProjectImage>
            </ImageContainer>
            <ProjectTitle isArabic={!isLatinCharacters(project.title)}>
              {project.title}
            </ProjectTitle>
          </ProjectCard>
        ))}
      </Container>
    </Wrapper>
  );
};
