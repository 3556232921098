import styled from "styled-components";
import { NavLink } from "react-router-dom";
import { SCREEN_SIZES } from "./screenSizes";
import { COLORS } from "./colors";

export const MenuItem = styled(NavLink)`
  //handles the styling for whenever the link is active
  ${({ hasActiveStyles }) =>
    hasActiveStyles &&
    `
  &[class*="active"] {
    opacity: 1;
    font-family: "GothamBold";
  }
  `}

  text-decoration: none;
  opacity: 0.8;
  cursor: pointer;
  margin-right: 30px;
  margin-top: 15px;
  font-size: 1rem;
  transition: opacity 0.3s ease;
  &:hover {
    opacity: 1;
  }

  @media only screen and (max-width: ${SCREEN_SIZES.laptop}) {
    font-size: 0.8rem;
  }

  @media only screen and (max-width: ${SCREEN_SIZES.mobile}) {
    font-size: 1.2rem;
    margin-top: 30px;
    color: ${COLORS.white};
  }
`;
