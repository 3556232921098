import styled from "styled-components";
import { COLORS } from "./colors";

export const Anchor = styled.a`
  color: ${COLORS.white};
  opacity: 0.8;
  cursor: pointer;
  margin-right: 30px;
  margin-top: 15px;
  font-size: 1rem;
  transition: opacity 0.3s ease;
  &:hover {
    opacity: 1;
  }
`;
