import React from "react";
import styled from "styled-components";
import { COLORS } from "../../styledComponents/colors";
import { SCREEN_SIZES } from "../../styledComponents/screenSizes";

export const MobileWrapper = styled.div`
  position: relative;
  z-index: 1;
  width: 60%;
  aspect-ratio: 2 / 1;

  @media only screen and (max-width: ${SCREEN_SIZES.laptop}) {
    width: 85%;
    margin-left: -15%;
  }

  @media only screen and (max-width: ${SCREEN_SIZES.tablet}) {
    width: 100%;
  }

  @media only screen and (max-width: ${SCREEN_SIZES.mobile}) {
    margin-left: -25px;
  }

  &:nth-child(2) {
    margin-top: -13%;
    margin-left: 40%;

    @media only screen and (max-width: ${SCREEN_SIZES.laptop}) {
      margin-left: 15%;
    }

    @media only screen and (max-width: ${SCREEN_SIZES.mobile}) {
      margin-left: 25px;
    }
  }
`;

//the landscape mobile is set as a border
export const Mobile = styled.div`
  margin: auto;
  border: 10px solid transparent;
  height: 100%;
  width: 100%;
  border-image: url(/assets/images/iphoneLandscape.png);
  border-image-slice: 15%;
  border-image-outset: 0;
  border-image-width: 45px 113px;
  border-radius: 35px;

  //set the screen content
  div {
    //note: if the content is a gif it can also be passed as a background-image
    background-image: url(${(props) => props.screen});
    background-color: ${COLORS.darkGrey};
    background-position: center;
    background-size: cover;
    z-index: 1;
  }

  //stacks the screen content behind the border
  div,
  iframe {
    height: 100%;
    width: 100%;
    position: relative;
    border-radius: 35px;
    object-fit: cover;
    z-index: -1;
  }
`;

export const LandscapeMobiles = ({ screens }) => {
  return (
    <>
      {screens?.map((screenContent, index) => (
        <MobileWrapper key={index}>
          <Mobile screen={screenContent.path}>
            <div />
          </Mobile>
        </MobileWrapper>
      ))}
    </>
  );
};
