import React from "react";
import styled from "styled-components";
import { useLangData } from "../../hooks/useLangData";
import { COLORS } from "../../styledComponents/colors";
import { TitleSection } from "../../components/titleSection";
import DOMPurify from "dompurify";
import { SCREEN_SIZES } from "../../styledComponents/screenSizes";

const DisplayedText = styled.h1`
  text-transform: uppercase;
  color: ${COLORS.white};
  font-size: 2.2rem;
  font-family: "GothamBold";

  @media only screen and (max-width: ${SCREEN_SIZES.mobile}) {
    font-size: 1.5rem;
  }
`;

const Content = styled.div`
  min-width: 800px;
  margin-top: 180px;
  margin-bottom: 100px;
  margin-left: auto;
  margin-right: auto;
  max-width: 57rem;
  text-align: center;
  font-family: "GothamBook";

  @media only screen and (max-width: ${SCREEN_SIZES.tablet}) {
    min-width: auto;
    padding: 0 45px;
  }
`;

export const LandingIntro = () => {
  const { servicesInfo: services } = useLangData(["servicesInfo"]);

  return (
    <TitleSection>
      <Content>
        <DisplayedText>What we do</DisplayedText>
        <br />
        <p
          dangerouslySetInnerHTML={{
            __html: DOMPurify.sanitize(services?.title),
          }}
        ></p>
      </Content>
    </TitleSection>
  );
};
