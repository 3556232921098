import React from "react";
import styled from "styled-components";
import { COLORS } from "../../styledComponents/colors";
import { Description } from "../../styledComponents";
import DOMPurify from "dompurify";
import { SCREEN_SIZES } from "../../styledComponents/screenSizes";

const Main = styled.div`
  position: relative;
  display: flex;
  flex-wrap: wrap;
`;

export const MobileWrapper = styled.div`
  position: relative;
  z-index: 1;
  width: 25%;
  height: fit-content;
  aspect-ratio: 1 / 2;

  @media only screen and (max-width: ${SCREEN_SIZES.laptop}) {
    width: 50%;
  }

  &:nth-child(2) {
    margin-top: 15%;
    margin-left: -10%;
  }
`;

export const ScreenDisplay = styled.div`
  background-color: ${COLORS.darkGrey};
  background-image: url(${(props) => props.screen});
  background-position: center;
  background-size: cover;
  height: 100%;
  position: relative;
  border-radius: 35px;
  object-fit: cover;
  z-index: -1;
`;

//the mobile is set as a border
export const Mobile = styled.div`
  border: 10px solid transparent;
  width: 100%;
  height: 100%;
  max-width: 500px;
  max-height: 1000px;
  border-image: url(/assets/images/iphone.png);
  border-image-slice: 15%;
  border-image-outset: 0;
  border-image-width: 98px 38px;
  border-radius: 35px;
  //handles the screen content and stacks it centered behind the border
  ${ScreenDisplay}
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: auto 120px;
  flex: 1;
  align-items: center;

  @media only screen and (max-width: ${SCREEN_SIZES.laptop}) {
    margin: 20px auto;
  }
`;

const Title = styled.h1`
  max-width: 30rem;
  font-size: 1.3rem;
  font-family: "GothamBold";
  margin-bottom: 20px;
`;

const TextDescription = styled(Description)`
  max-width: 30rem;
`;

export const Mobiles = ({ screens, title, description }) => {
  return (
    <Main>
      {screens?.map((screenContent, index) => (
        <MobileWrapper key={index}>
          <Mobile>
            <ScreenDisplay screen={screenContent.path} />
          </Mobile>
        </MobileWrapper>
      ))}

      <TextContainer>
        <Title
          dangerouslySetInnerHTML={{
            __html: DOMPurify.sanitize(title),
          }}
        />
        <TextDescription
          dangerouslySetInnerHTML={{
            __html: DOMPurify.sanitize(description),
          }}
        ></TextDescription>
      </TextContainer>
    </Main>
  );
};
