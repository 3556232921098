import React, { forwardRef, useContext, useEffect } from "react";
import styled, { css, keyframes } from "styled-components";
import { HeaderSection } from "../../components/headerSection";
import { ProjectOverview } from "./projectOverview";
import { COLORS } from "../../styledComponents/colors";
import { Anchor, FlexColumn, FlexRow } from "../../styledComponents";
import { useLangData } from "../../hooks/useLangData";
import { NextProjectPreview } from "./nextProjectPreview";
import { Item } from "./item";
import { MetaDecorator } from "../../utils/metaDecorator";
import { useNavigate } from "react-router";
import { useTranslation } from "react-i18next";
import LanguageContext from "../../contexts/languagesContext";
import {
  TwitterIcon,
  FacebookIcon,
  LinkedinIcon,
} from "../../components/icons";

const AnchorIcon = styled(Anchor)`
  align-items: center;
  display: flex;
  margin: 5px 9px;
`;

const animateTop = keyframes`
  from {
    top:  calc(-100px + ${({ yOffest }) => yOffest}px);
  }

  to {
    top:0;
  }
`;

const fadeOutAnimation = keyframes`
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
`;

const fadeInAnimation = keyframes`
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
`;

const SocialIconsContainer = styled(FlexColumn)`
  position: flex;
  margin: auto;
  padding: 60px 100px;

  h1 {
    text-align: center;
    margin-bottom: 10px;
    text-transform: uppercase;
  }
`;

const Container = styled(FlexColumn)`
  position: relative;
  background-color: ${COLORS.white};
  padding-top: 180px;
  opacity: 1;
  min-height: 100vh;
  width: 100%;

  ${({ fadeOut, yOffset }) =>
    fadeOut &&
    css`
      position: absolute;
      bottom: calc(100vh - 480px - ${yOffset}px);
      z-index: 2;
      animation: ${fadeOutAnimation} ease forwards;
      animation-duration: 0.5s;
    `}

  ${({ scrollUp, yOffset }) =>
    scrollUp &&
    css`
      position: absolute;
      top: calc(-100px + ${yOffset}px);
      animation: ${animateTop} cubic-bezier(0.59, 0.01, 0.28, 1) forwards;
      animation-duration: 0.7s;
      animation-delay: 0.3s;

      header {
        opacity: 0;
        animation: ${fadeInAnimation} ease forwards;
        animation-duration: 0.3s;
        animation-delay: 1s;
      }
    `}
`;
const IconsContainer = styled(FlexRow)`
  justify-content: center;
`;

const SubTitle = styled.span`
  font-size: 0.8rem;
  text-align: center;
  text-transform: uppercase;
  margin-bottom: 5px;
`;

export const PageContent = forwardRef(
  ({ projectId, fadeOut, scrollUp, yOffset }, ref) => {
    const navigate = useNavigate();
    const { projects } = useLangData(["projects"]);
    const { language } = useContext(LanguageContext);
    const projectIndex = projects?.findIndex((item) => item.slug === projectId);
    const project = projects?.[projectIndex];
    const { t } = useTranslation();

    useEffect(() => {
      if (projectId && projects?.length > 0 && !project) {
        navigate(`/${language}/notfound`);
      }
      // eslint-disable-next-line
    }, [projects, project, projectId]);

    if (!project) {
      return null;
    }
    const nextProject = projects?.[projectIndex + 1] || projects?.[0];
    const { components } = project;
    const pageUrl = encodeURIComponent(window.location.href);
    return (
      <Container fadeOut={fadeOut} scrollUp={scrollUp} yOffset={yOffset || 0}>
        <HeaderSection color="light" />
        {project && (
          <>
            <MetaDecorator
              title={project.title}
              description={project.description}
              type="article"
              image={project.preview.resource}
              alt={project.title}
            />
            <ProjectOverview project={project} />
            {components?.map((item, index) => (
              <Item
                item={{ type: item.type, ...item.details }}
                key={index}
                firstElement={index === 0}
              />
            ))}
          </>
        )}

        <SocialIconsContainer>
          <SubTitle>{t("shareProject")}</SubTitle>
          <IconsContainer>
            <AnchorIcon
              href={`https://www.facebook.com/sharer/sharer.php?u=${pageUrl}&amp;src=sdkpreparse`}
              target="_blank"
            >
              <FacebookIcon height="29" color={COLORS.black} />
            </AnchorIcon>
            <AnchorIcon
              href={`https://twitter.com/intent/tweet?url=${pageUrl}`}
              target="_blank"
            >
              <TwitterIcon height="25" color={COLORS.black} />
            </AnchorIcon>
            <AnchorIcon
              href={`https://www.linkedin.com/sharing/share-offsite/?url=${pageUrl}`}
              target="_blank"
            >
              <LinkedinIcon color={COLORS.black} />
            </AnchorIcon>
          </IconsContainer>
        </SocialIconsContainer>
        {nextProject && (
          <div ref={ref}>
            <NextProjectPreview project={nextProject}></NextProjectPreview>
          </div>
        )}
      </Container>
    );
  }
);
