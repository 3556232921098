import { useLocation } from "react-router-dom";

function formatPath(initialPathname) {
  let new_path = initialPathname.split("/");
  new_path.splice(0, 2);
  new_path = new_path.join("/");
  new_path = "/".concat(new_path);
  return new_path;
}

export const usePathFormatting = () => {
  const { pathname } = useLocation();
  const pathNameValue = formatPath(pathname);
  return pathNameValue;
};
