import React from "react";
import styled from "styled-components";
import { ComponentMapper } from "./consts";
import { COLORS } from "../../styledComponents/colors";
import { SCREEN_SIZES } from "../../styledComponents/screenSizes";

const Wrapper = styled.div`
  padding: 0px 120px;

  @media only screen and (max-width: ${SCREEN_SIZES.tablet}) {
    padding: 0 70px;
  }

  @media only screen and (max-width: ${SCREEN_SIZES.mobile}) {
    padding: 0 25px;
  }

  ${({ greyBackground }) =>
    greyBackground && `background-color: ${COLORS.lighterGrey};`}

  ${({ padding }) =>
    padding &&
    `padding:  20px 120px;

  @media only screen and (max-width: ${SCREEN_SIZES.tablet}) {
    padding:  20px 70px;
  }

  @media only screen and (max-width: ${SCREEN_SIZES.mobile}) {
    padding: 20px 35px;
  }

`}
${({ firstElement }) =>
    firstElement &&
    `padding: 0 120px 60px 120px;

    @media only screen and (max-width: ${SCREEN_SIZES.tablet}) {
    padding: 0 35px 60px 35px;
  }

  @media only screen and (max-width: ${SCREEN_SIZES.mobile}) {
    padding: 0 25px 20px 25px;
  }
`}
`;

const Row = styled.div`
  flex: 1;
  margin: 0 auto;
`;

export const Item = ({ item, firstElement }) => {
  return (
    <Wrapper
      firstElement={firstElement}
      greyBackground={item.greyBackground}
      padding={item.padding}
    >
      <Row firstElement={firstElement}>
        {React.createElement(ComponentMapper[item.type], { ...item })}
      </Row>
    </Wrapper>
  );
};
