import React from "react";
import styled from "styled-components";
import { SCREEN_SIZES } from "../../styledComponents/screenSizes";

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 80%;
  margin: 0 auto;
  @media only screen and (max-width: ${SCREEN_SIZES.tablet}) {
    width: 100%;
  }
`;

const Image = styled.img`
  width: 140px;
  padding: 15px;
  margin: 0 8px;
`;

export const Icons = ({ icons }) => {
  return (
    <Container>
      {icons?.map((item, index) => (
        <Image key={index} src={item.path} alt={item.alt} />
      ))}
    </Container>
  );
};
