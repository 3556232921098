import { createContext, useState, useEffect } from "react";
import { getHomepageData } from "../services";
import i18n from "i18next";

const LanguageContext = createContext({
  language: "",
  selectLanguage: (lang) => {},
  data: undefined,
  storeData: (data, lang) => {},
  getData: (lang) => {},
});

export const LanguageContextProvider = (props) => {
  //set the detected language from the i18n configuartion as the global language (resolved language ensures that the region isn't taken into account)
  const [language, setLanguage] = useState(`${i18n.resolvedLanguage}`);
  const [data, setData] = useState({});
  const selectLanguageHandler = (lang) => {
    setLanguage(lang);
  };

  const storeData = (newdata, lang) => {
    const updatedData = { ...data };
    updatedData[lang] = newdata;

    setData(updatedData);
  };

  const getData = (properties) => {
    const response = {};
    properties.forEach((element) => {
      response[element] = data[language]?.[element];
    });
    return response;
  };

  const context = {
    language,
    selectLanguage: selectLanguageHandler,
    data: data,
    storeData: storeData,
    getData: getData,
  };

  useEffect(() => {
    const fetchData = async (lang) => {
      await getHomepageData(lang).then((resp) => {
        // TODO: remove this once the api is correct
        resp?.projects?.map((project) => {
          return project.components?.map((item) => {
            if (item.details?.title?.en) {
              item.details.title = item.details.title.en;
            }
            if (item.details?.title?.fr) {
              item.details.title = item.details.title.fr;
            }
            if (item.details?.description?.en) {
              item.details.description = item.details.description.en;
            }
            if (item.details?.description?.fr) {
              item.details.description = item.details.description.fr;
            }
            return item;
          });
        });
        storeData(resp, lang);
      });
    };

    if (!data[language]) {
      fetchData(language);
    }

    // eslint-disable-next-line
  }, [language, data]);

  return (
    <LanguageContext.Provider value={context}>
      {props.children}
    </LanguageContext.Provider>
  );
};
export default LanguageContext;
