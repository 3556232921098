import { useContext } from "react";
import { Link as RouterLink } from "react-router-dom";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import LanguageContext from "../../contexts/languagesContext";
import { COLORS } from "../../styledComponents/colors";
import { ProjectOverview } from "./projectOverview";
import { Item } from "./item";
import { SCREEN_SIZES } from "../../styledComponents/screenSizes";

const Container = styled(RouterLink)`
  display: flex;
  flex-direction: column;
  height: 480px;
  overflow: hidden;
`;

const HR = styled.hr`
  margin: 15px 120px;
  flex-basis: 1px;
  flex-grow: 0;
  border-top: 1px solid ${COLORS.grey};

  @media only screen and (max-width: ${SCREEN_SIZES.tablet}) {
    margin: 15px 35px;
  }
`;

const Link = styled.span`
  text-decoration: none;
  font-size: 16px;
  color: ${COLORS.lightBlue};
  margin: 15px 120px;
  font-family: "GothamBold";

  @media only screen and (max-width: ${SCREEN_SIZES.tablet}) {
    margin: 15px 35px;
  }
`;

export const NextProjectPreview = ({ project }) => {
  const { t } = useTranslation();
  const context = useContext(LanguageContext);
  const language = context.language;

  const { components } = project;

  return (
    <Container to={`/${language}/work/${project.slug}`}>
      <HR />
      <Link>{t("projects.nextProject")}</Link>
      <ProjectOverview project={project} />
      {components?.length > 0 && (
        <Item
          item={{
            type: components[0].type,
            ...components[0].details,
            isPreviewComponent: true,
          }}
          firstElement
        />
      )}
    </Container>
  );
};
