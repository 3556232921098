import { useState } from "react";
import styled from "styled-components";
import DOMPurify from "dompurify";
import { useLangData } from "../../hooks/useLangData";
import { ApproachVisual } from "./approachVisual";
import {
  Section,
  FlexColumn,
  FlexRow,
  Description,
  SubTitle,
  List,
  Div,
  ListItem,
  ListTitle,
} from "../../styledComponents";
import { SCREEN_SIZES } from "../../styledComponents/screenSizes";
import { COLORS } from "../../styledComponents/colors";

const Container = styled(Section)`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 105px 60px 60px 60px;
  background: ${COLORS.darkBlue};
  color: ${COLORS.white};
  min-height: 600px;

  @media only screen and (max-width: ${SCREEN_SIZES.tablet}) {
    padding: 85px 25px 25px 25px;
    flex-direction: column-reverse;
  }
`;

const ContainerVisual = styled(FlexRow)`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-100%, -50%);

  @media only screen and (max-width: ${SCREEN_SIZES.tablet}) {
    position: relative;
    transform: none;
    left: 0;
    top: 0;
    order: 2;
    margin: 0 auto;
  }
`;

const Content = styled(Div)`
  max-width: 40rem;

  @media only screen and (max-width: ${SCREEN_SIZES.tablet}) {
    &:nth-child(3) {
      order: 3;
    }
    &:nth-child(2) {
      order: 1;
    }
  }
`;

const Wrapper = styled(FlexColumn)`
  padding-left: 50%;

  @media only screen and (max-width: ${SCREEN_SIZES.tablet}) {
    padding-left: 0;
  }
`;

export const Approach = () => {
  const { approach } = useLangData(["approach"]);
  const [highlightedIndex, setHighlightedIndex] = useState(-1);

  const onLabelHover = (index) => {
    setHighlightedIndex(index);
  };

  const resetLabelHover = () => {
    setHighlightedIndex(-1);
  };

  return (
    <Container>
      <Wrapper>
        <ContainerVisual>
          <ApproachVisual
            onLabelHover={onLabelHover}
            resetLabelHover={resetLabelHover}
          />
        </ContainerVisual>
        <Content>
          <SubTitle color={COLORS.white}>{approach?.title}</SubTitle>
          <Description
            dangerouslySetInnerHTML={{
              __html: DOMPurify.sanitize(approach?.description),
            }}
          />
        </Content>
        <Content>
          <List>
            {approach?.informationList.map((item, index) => (
              <ListItem
                key={index}
                dim={highlightedIndex > -1}
                highlight={highlightedIndex === index}
              >
                <ListTitle>{item.title}</ListTitle>
                <p>{item.description}</p>
              </ListItem>
            ))}
          </List>
        </Content>
      </Wrapper>
    </Container>
  );
};
