import { useRef } from "react";
import { Link as RouterLink } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import DOMPurify from "dompurify";
import { useTranslation } from "react-i18next";
import { useLangData } from "../../hooks/useLangData";
import {
  Section,
  H2,
  Description,
  FlexColumn,
  ButtonIconAnimationWhite,
} from "../../styledComponents";
import { COLORS } from "../../styledComponents/colors";
import { SCREEN_SIZES } from "../../styledComponents/screenSizes";
import rightArrow from "../../assets/icons/rightArrowTeam.svg";
import leftArrow from "../../assets/icons/leftArrowTeam.svg";
import buttonRightArrowBlue from "../../assets/icons/buttonRightArrowBlue.svg";

const Wrapper = styled(Section)`
  background-color: ${COLORS.darkerBlue};
  padding: 0 0 30px 100px;

  @media only screen and (max-width: ${SCREEN_SIZES.laptop}) {
    padding: 0 0 10px 100px;
  }

  @media only screen and (max-width: ${SCREEN_SIZES.tablet}) {
    padding: 0;
  }
`;

const Container = styled.div`
  color: white;
  display: flex;
  margin: 0 auto;
  @media only screen and (max-width: ${SCREEN_SIZES.tablet}) {
    flex-direction: column;
  }
`;

const TitleContainer = styled(FlexColumn)`
  padding-top: 60px;
  display: flex;
  flex-direction: column;
  justify-content: center;

  @media only screen and (max-width: ${SCREEN_SIZES.laptop}) {
    padding-top: 35px;
  }

  @media only screen and (max-width: ${SCREEN_SIZES.mobile}) {
    padding-top: 40px;
  }
`;

const Title = styled(H2)`
  font-family: "GothamBook";
  font-size: 2.3rem;
  line-height: 1;
  padding-right: 10px;
  width: 14rem;
  text-transform: uppercase;

  @media only screen and (max-width: ${SCREEN_SIZES.laptop}) {
    font-size: 1.8rem;
  }

  @media only screen and (max-width: ${SCREEN_SIZES.tablet}) {
    width: 30rem;
    font-family: "GothamBold";
    margin: 40px 0px 0px 50px;
  }

  @media only screen and (max-width: ${SCREEN_SIZES.mobile}) {
    margin: 25px 0px 0px 25px;
    font-size: 1.5rem;
  }
`;

const Content = styled.div`
  overflow-x: scroll;
  overflow-y: hidden;
  white-space: nowrap;
  padding-left: 30px;
  padding-top: 60px;
  display: flex;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    width: 0px;
  }
  &::-webkit-scrollbar * {
    background: transparent;
  }
  &::-webkit-scrollbar-thumb {
    background: transparent !important;
  }
  @media only screen and (max-width: ${SCREEN_SIZES.laptop}) {
    padding-top: 35px;
  }

  @media only screen and (max-width: ${SCREEN_SIZES.tablet}) {
    padding-left: 0px;
    flex-wrap: wrap;
    justify-content: space-evenly;
  }

  @media only screen and (max-width: ${SCREEN_SIZES.mobile}) {
    padding-top: 40px;
    padding-left: 25px;
    padding-right: 25px;
    height: 300px;
  }
`;

const Item = styled(RouterLink)`
  display: inline-block;
  cursor: pointer;
  height: 100%;
  width: 250px;
  margin-right: 60px;
  text-align: center;
  position: relative;
  color: ${COLORS.white};

  @media only screen and (max-width: ${SCREEN_SIZES.laptop}) {
    width: 150px;
    margin-right: 30px;
  }

  @media only screen and (max-width: ${SCREEN_SIZES.tablet}) {
    height: fit-content;
    flex-wrap: wrap;
    margin-bottom: 70px;
    margin-right: 0px;
    display: flex;
    justify-content: center;
    pointer-events: initial !important;
  }

  @media only screen and (max-width: ${SCREEN_SIZES.mobile}) {
    width: calc((100vw - 90px) / 3);
    margin-bottom: 30px;
  }
`;

const ImageContainer = styled.div`
  border-radius: 50%;
  width: 180px;
  height: 180px;
  padding: 5px;
  border: 2px solid ${COLORS.darkGrey};
  transition: transform 0.3s ease;
  margin-bottom: 5px;

  ${Item}:hover & {
    transform: translateY(-3rem);
  }

  @media only screen and (max-width: ${SCREEN_SIZES.laptop}) {
    width: 150px;
    height: 150px;
    ${Item}:hover & {
      transform: translateY(-2rem);
    }
  }

  @media only screen and (max-width: ${SCREEN_SIZES.tablet}) {
    flex-wrap: wrap;
    ${Item}:hover & {
      transform: translateY(0);
    }
  }

  @media only screen and (max-width: ${SCREEN_SIZES.mobile}) {
    width: calc((100vw - 90px) / 3);
    height: calc((100vw - 90px) / 3);
    border: 0;
  }
`;

const Image = styled.img`
  width: 100%;
  height: 100%;
  border-radius: 50%;
`;

const ItemDescription = styled(Description)`
  transition: transform 0.3s ease;
  position: absolute;
  bottom: 0;
  left: 0px;
  right: 0px;
  overflow: hidden;
  text-overflow: ellipsis;
  transform: translateY(100%);
  @media only screen and (max-width: ${SCREEN_SIZES.laptop}) {
    font-size: 0.8rem;
  }

  ${Item}:hover & {
    transform: translateY(0);
  }
  > p {
    font-size: 0.8rem;
  }

  @media only screen and (max-width: ${SCREEN_SIZES.tablet}) {
    ${Item}:hover & {
      transform: translateY(100%);
    }
  }

  @media only screen and (max-width: ${SCREEN_SIZES.mobile}) {
    display: none;
  }
`;

const IconsContainer = styled.div`
  height: 30px;
  width: 80px;
  display: flex;
  justify-content: space-between;
  margin-top: 25px;

  @media only screen and (max-width: ${SCREEN_SIZES.laptop}) {
    margin-top: 15px;
  }

  @media only screen and (max-width: ${SCREEN_SIZES.tablet}) {
    display: none;
  }
`;

const Icon = styled.button`
  border-radius: 50%;
  width: 32px;
  height: 32px;
  text-align: center;
  cursor: pointer;
  opacity: 0.5;
  transition: opacity 0.3s ease;
  background-size: 100%;
  background-position: center;
  background-repeat: no-repeat;
  &:hover {
    opacity: 1;
  }

  &:nth-child(1) {
    background-image: url(${leftArrow});
  }
  &:nth-child(2) {
    background-image: url(${rightArrow});
  }
`;

const ButtonMeet = styled(ButtonIconAnimationWhite)`
  display: none;
  align-self: center;
  margin-bottom: 40px;
  &:after {
    opacity: 1;
  }

  @media only screen and (max-width: ${SCREEN_SIZES.mobile}) {
    margin-top: 25px;
  }

  @media only screen and (max-width: ${SCREEN_SIZES.tablet}) {
    display: block;
  }
`;

const LinkItem = styled(Item)`
  color: ${COLORS.blue};
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "GothamBold";
  padding-right: 40px;
  text-decoration: none;

  &:after {
    content: "";
    background-image: url(${buttonRightArrowBlue});
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translate(-10px, -50%);
    opacity: 0;
    transition: opacity 0.2s ease, transform 0.3s ease;
    width: 20px;
    height: 14px;
  }

  &:hover {
    &:after {
      opacity: 1;
      transform: translate(0, -50%);
    }
  }
  @media only screen and (max-width: ${SCREEN_SIZES.tablet}) {
    display: none;
  }
`;

export const Team = () => {
  const { t } = useTranslation();
  const { team } = useLangData(["team"]);
  const content = useRef(null);
  const link = useRef(null);
  const navigate = useNavigate();
  const navigateToTeamPage = () => {
    navigate(`team`);
  };

  const scrollContent = (direction) => {
    if (team && team.people) {
      const itemNumber = team.people.length;
      const sizePerItem =
        (content.current.scrollWidth - link.current.offsetWidth) / itemNumber;

      // console.log(content.current.scrollWidth);
      // console.log("sizePerItem", sizePerItem);
      // console.log("windowwidth", window.innerWidth);

      //console.log("scrollWidth", content.current.scrollWidth);
      //console.log("sizePerItem", sizePerItem);
      //console.log("itemNumber", itemNumber);
      //console.log("scrollTo", scrollBy);
      //console.log("currentPosition", currentPosition);
      //const currentPosition = content.current.scrollLeft;

      const scrollBy = sizePerItem * 4;

      content.current.scrollBy({
        left: direction === "right" ? scrollBy : -scrollBy,
        behavior: "smooth",
      });
    }
  };
  return (
    <Wrapper>
      <Container>
        <TitleContainer>
          <Title
            dangerouslySetInnerHTML={{
              __html: DOMPurify.sanitize(t("theTeamText")),
            }}
          ></Title>
          <IconsContainer>
            <Icon onClick={() => scrollContent("left")}></Icon>
            <Icon onClick={() => scrollContent("right")}></Icon>
          </IconsContainer>
        </TitleContainer>
        <Content ref={content}>
          {team?.people.slice(0, 7).map((item, index) => (
            <Item key={index} to="team">
              <ImageContainer>
                <Image src={item.photoUrl} />
              </ImageContainer>
              <ItemDescription>
                <b>{item.fullName}</b>
                <br />
                {item.title}
              </ItemDescription>
            </Item>
          ))}
          <LinkItem ref={link} to={`team`}>
            {t("team.meet")}
          </LinkItem>
        </Content>
        <ButtonMeet onClick={navigateToTeamPage}>
          {t("team.meetMobile")}
        </ButtonMeet>
      </Container>
    </Wrapper>
  );
};
