import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { FooterSection } from "../../components/footerSection";
import { PeopleList } from "./peopleList";
import { HeaderSection } from "../../components/headerSection";
import { MetaDecorator } from "../../utils/metaDecorator";
import { LandingIntro } from "../team/landingIntro";

export const Team = () => {
  const { t } = useTranslation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <MetaDecorator
        title={t("metaTags.servicesTitle")}
        description={t("metaTags.servicesDescription")}
        type="website"
        alt="Spring services"
      />
      <HeaderSection color="dark" />
      <LandingIntro />
      <PeopleList />
      <FooterSection />
    </>
  );
};
