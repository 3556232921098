import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { useLangData } from "../../hooks/useLangData";
import { SCREEN_SIZES } from "../../styledComponents/screenSizes";
import {
  Section,
  FlexRow,
  ColumnTitle,
  ColumnList,
  ColumnItem,
  ColumnLink,
  SubTitle,
  Description,
} from "../../styledComponents";
import { COLORS } from "../../styledComponents/colors";

const Container = styled(Section)`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${COLORS.white};
  padding: 50px 25px 0 25px;
  margin-bottom: -45px;

  @media only screen and (max-width: ${SCREEN_SIZES.mobile}) {
    padding: 30px 25px 0 25px;
  }
`;

const Content = styled(FlexRow)`
  justify-content: center;
  display: flex;
  flex-direction: column;
  max-width: 70rem;

  @media only screen and (max-width: ${SCREEN_SIZES.laptop}) {
    max-width: 60rem;
  }
`;

const Header = styled.div`
  max-width: 40rem;
  margin-bottom: 40px;

  @media only screen and (max-width: ${SCREEN_SIZES.mobile}) {
    margin-bottom: 20px;
  }
`;

export const Services = () => {
  const { t } = useTranslation();
  const { servicesInfo: services, purpose } = useLangData([
    "servicesInfo",
    "purpose",
  ]);

  return (
    <Container>
      <Content>
        <Header>
          <SubTitle>{purpose?.content.title}</SubTitle>
          <Description>{purpose?.content.description}</Description>
        </Header>

        <ColumnList>
          {services?.servicesList.map((item, index) => (
            <ColumnItem key={item.slug} to={"services/" + item.slug}>
              <ColumnTitle>
                {item.title}
                <br />
                <ColumnLink to={"services/" + item.slug}>
                  {t("learnMore")}
                </ColumnLink>
              </ColumnTitle>
            </ColumnItem>
          ))}
        </ColumnList>
      </Content>
    </Container>
  );
};
