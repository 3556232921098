import styled from "styled-components";
import { COLORS } from "./colors";
import { SCREEN_SIZES } from "./screenSizes";
import buttonRightArrowBlue from "../assets/icons/buttonRightArrowBlue.svg";
import buttonRightArrowWhite from "../assets/icons/buttonRightArrowWhite.svg";

export const Button = styled.button`
  font-family: "GothamBold";
  border: 2px solid ${COLORS.blue};
  color: ${COLORS.blue};
  padding: 20px;
  text-align: center;
  font-size: 1rem;
  cursor: pointer;

  @media only screen and (max-width: ${SCREEN_SIZES.laptop}) {
    font-size: 0.8rem;
    padding: 10px;
  }

  @media only screen and (max-width: ${SCREEN_SIZES.mobile}) {
    font-size: 0.6rem;
  }
`;

export const ButtonIcon = styled(Button)`
  position: relative;
  padding-right: 40px;

  @media only screen and (max-width: ${SCREEN_SIZES.laptop}) {
    padding-right: 35px;
  }

  @media only screen and (max-width: ${SCREEN_SIZES.tablet}) {
    padding-right: 35px;
  }

  @media only screen and (max-width: ${SCREEN_SIZES.mobile}) {
    padding-right: 25px;
  }

  &:after {
    content: "";
    background-image: url(${buttonRightArrowWhite});
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    width: 20px;
    height: 14px;

    @media only screen and (max-width: ${SCREEN_SIZES.laptop}) {
      width: 10px;
      height: 10px;
      right: 15px;
    }

    @media only screen and (max-width: ${SCREEN_SIZES.tablet}) {
      right: 10px;
      transition: none;
      opacity: 1;
      transform: translate(0, -50%);
    }
    @media only screen and (max-width: ${SCREEN_SIZES.mobile}) {
      width: 9.5px;
      height: 7px;
      right: 10px;
      transition: none;
      opacity: 1;
      transform: translate(0, -50%);
    }
  }
`;

export const ButtonIconAnimation = styled(Button)`
  position: relative;
  transition: padding-right 0.3s ease;

  @media only screen and (max-width: ${SCREEN_SIZES.tablet}) {
    padding-right: 35px;
  }

  @media only screen and (max-width: ${SCREEN_SIZES.mobile}) {
    padding-right: 25px;
  }
  &:after {
    content: "";
    background-image: url(${buttonRightArrowBlue});
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translate(-10px, -50%);
    opacity: 0;
    transition: opacity 0.2s ease, transform 0.3s ease;
    width: 20px;
    height: 14px;

    @media only screen and (max-width: ${SCREEN_SIZES.laptop}) {
      right: 10px;
      width: 15px;
      height: 10px;
    }

    @media only screen and (max-width: ${SCREEN_SIZES.mobile}) {
      width: 9.5px;
      height: 7px;
      right: 10px;
      transition: none;
      opacity: 1;
      transform: translate(0, -50%);
    }

    @media only screen and (max-width: ${SCREEN_SIZES.tablet}) {
      right: 10px;
      transition: none;
      opacity: 1;
      transform: translate(0, -50%);
    }
  }

  &:hover {
    padding-right: 40px;

    @media only screen and (max-width: ${SCREEN_SIZES.laptop}) {
      padding-right: 35px;
    }

    &:after {
      opacity: 1;
      transform: translate(0, -50%);
    }
  }
`;

export const ButtonIconAnimationWhite = styled(ButtonIconAnimation)`
  color: ${COLORS.white};
  border-color: ${COLORS.white};
  &:after {
    background-image: url(${buttonRightArrowWhite});
  }
`;
