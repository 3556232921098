import { useEffect, useState } from "react";
import styled, { keyframes, css } from "styled-components";
import { pulseAnimation } from "../../components/animation";
import { COLORS } from "../../styledComponents/colors";
import { SCREEN_SIZES } from "../../styledComponents/screenSizes";

const CENTER_WIDTH = 100;
const CIRCLE_POSITION = {
  top: `top: 0; left: 0;`,
  left: `bottom: 0; left: 0;`,
  right: `top: 0; right:0;`,
  bottom: `bottom: 0; right: 0;`,
};

const LABEL_POSITION = {
  top: `top: -20px; left: 50%; transform: translateX(-50%);`,
  left: `top: 50%; left: -5px; transform: translate(-100%, -50%);`,
  right: `top: 50%; right: -5px; transform: translate(100%, -50%);`,
  bottom: `bottom: -25px; left: 50%; transform: translateX(-50%);`,
};

const LABEL_POSITION_MOBILE = {
  top: `top: 5px; left: 50%; transform: translateX(-50%);`,
  left: `top: 50%; left: 25px; transform: translate(-100%, -50%);`,
  right: `top: 50%; right: 25px; transform: translate(100%, -50%);`,
  bottom: `bottom: 5px; left: 50%; transform: translateX(-50%);`,
};

const LABEL_CONTENT = {
  top: "Content",
  left: "Conversion",
  right: "Connectivity",
  bottom: "Community",
};
const Container = styled.div`
  padding: 187px;
  position: relative;
  display: inline-block;
  margin: 25px calc(8rem + 25px) 25px 8rem;
  width: 0;
  height: 0;

  @media only screen and (max-width: ${SCREEN_SIZES.laptop}) {
    padding: 150px;
  }

  @media only screen and (max-width: ${SCREEN_SIZES.tablet}) {
    margin: 50px 25px 100px 25px;
  }

  @media only screen and (max-width: ${SCREEN_SIZES.mobile}) {
    margin: 0px;
  }

  @media only screen and (min-width: ${SCREEN_SIZES.large}) {
    margin: 25px calc(8rem + 50px) 25px 8rem;
  }
`;

const ripplesEffect = keyframes`
  0% {
    transform: scale(1);
    opacity: 0;
  }
  5% {
    opacity: 1;
  }
  30%{
    opacity: 1;
  }
  100%{
    transform: scale(1.4);
    opacity:0;
  }
`;

const CenterContainer = styled.div`
  width: 0;
  height: 0;
  opacity: 0;
  width: ${`${CENTER_WIDTH}px`};
  height: ${`${CENTER_WIDTH}px`};
  opacity: 1;
  transition: width 1s ease, height 1s ease, opacity 0.7s ease;
  ${({ animate }) =>
    animate &&
    `
  width:  ${`${CENTER_WIDTH}px`};
  height:  ${`${CENTER_WIDTH}px`};
  opacity: 1;
 
  `}

  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const CenterAnimationRipple = css`
  &:before,
  &:after {
    content: "";
    width: 100%;
    height: 100%;
    border-radius: 50%;
    position: absolute;
    border: 1px solid ${COLORS.grey};
    opacity: 0;
    animation: ${ripplesEffect} ease infinite;
    animation-duration: 5s;
    animation-delay: 2s;
  }

  &:before {
    animation-delay: 3s;
  }
`;

const Center = styled.h1`
  background-color: ${COLORS.white};
  color: ${COLORS.darkBlue};
  border-radius: 50%;
  width: 100%;
  height: 100%;
  font-size: 16px;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;

  animation-name: ${pulseAnimation};
  animation-duration: 5s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;

  ${CenterAnimationRipple}
  ${({ animate }) => animate && CenterAnimationRipple}
`;

const Circle = styled.div`
  width: 7px;
  height: 7px;
  border-radius: 50%;
  position: absolute;
  background-color: ${COLORS.white};
  ${(props) => props.position && CIRCLE_POSITION[props.position]};

  opacity: 1;
  transition: opacity 0.5s ease;
  ${({ animate }) => animate && `opacity: 1;`}

  &:nth-child(7) {
    transition: opacity 1s ease 2s;
  }
  &:nth-child(9) {
    transition: opacity 1s ease 3s;
  }
  &:nth-child(10) {
    transition: opacity 1s ease 4s;
  }
  &:nth-child(8) {
    transition: opacity 1s ease 5s;
  }
`;

const LinesContainer = styled.div`
  width: 70%;
  height: 70%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) rotate(45deg);

  @media only screen and (max-width: ${SCREEN_SIZES.mobile}) {
    width: 55%;
    height: 55%;
  }
`;

const VerticalLine = styled.div`
  width: 1px;
  height: 0%;
  margin: 10% 0;
  background-color: ${COLORS.white};
  position: absolute;
  top: 0;
  left: ${(props) => (props.position === "right" ? 0 : "100%")};

  height: 80%;
  transition: height 1s ease 8s;
  ${({ animate }) => animate && `height: 80%;`}
`;

const HorizontalLine = styled.div`
  height: 1px;
  width: 0%;
  margin: 0 10%;
  background-color: ${COLORS.white};
  position: absolute;
  left: 0;
  top: ${(props) => (props.position === "top" ? 0 : "100%")};

  width: 80%;
  transition: width 1s ease 8s;
  ${({ animate }) => animate && `width: 80%;`}
`;

const DiagonalLine = styled.hr`
  border-top: 1px dashed ${COLORS.white};
  width: 0%;
  height: 1px;
  transform: translate(-50%, -50%)
    rotate(${(props) => props.vertical && "-"}45deg);
  transform-origin: center;
  position: absolute;
  top: 50%;
  left: 50%;

  width: 120%;
  transition: width 1s ease ${(props) => (props.vertical ? 6 : 7)}s;
  ${({ animate }) => animate && `width: 120%;`}
`;

const Label = styled.label`
  position: absolute;
  font-family: "GothamBold";
  font-size: 18px;
  cursor: default;
  ${(props) => props.position && LABEL_POSITION[props.position]};

  opacity: 1;
  transition: opacity 1s ease;
  ${({ animate }) => animate && `opacity: 1;`}

  &:nth-child(3) {
    transition: opacity 1s ease 2s;
  }
  &:nth-child(5) {
    transition: opacity 1s ease 3s;
  }
  &:nth-child(6) {
    transition: opacity 1s ease 4s;
  }
  &:nth-child(4) {
    transition: opacity 1s ease 5s;
  }

  &:before {
    content: "${(props) => props.position && LABEL_CONTENT[props.position]}";
  }

  @media only screen and (max-width: ${SCREEN_SIZES.mobile}) {
    ${(props) => props.position && LABEL_POSITION_MOBILE[props.position]};

    &:before {
      content: "C";
    }
  }
`;

export const ApproachVisual = ({ onLabelHover, resetLabelHover }) => {
  const [startAnimation, setStartAnimation] = useState(false);

  const scrollAnimation = () => {
    let scrollTop = window.pageYOffset;
    if (scrollTop >= window.innerHeight - 100) {
      window.removeEventListener("scroll", scrollAnimation);
      setTimeout(() => {
        setStartAnimation(true);
      }, 1000);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", scrollAnimation);
    scrollAnimation();

    return () => {
      window.removeEventListener("scroll", scrollAnimation);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Container>
      <LinesContainer>
        <VerticalLine position="right" animate={startAnimation} />
        <VerticalLine position="left" animate={startAnimation} />
        <HorizontalLine position="top" animate={startAnimation} />
        <HorizontalLine position="bottom" animate={startAnimation} />

        <DiagonalLine vertical={true} animate={startAnimation} />
        <DiagonalLine vertical={false} animate={startAnimation} />

        <Circle position="top" animate={startAnimation} />
        <Circle position="left" animate={startAnimation} />
        <Circle position="right" animate={startAnimation} />
        <Circle position="bottom" animate={startAnimation} />
      </LinesContainer>

      <CenterContainer animate={startAnimation}>
        <Center animate={startAnimation}>Purpose</Center>
      </CenterContainer>

      <Label
        position="top"
        animate={startAnimation}
        onMouseOver={() => onLabelHover(0)}
        onMouseLeave={resetLabelHover}
      ></Label>
      <Label
        position="left"
        animate={startAnimation}
        onMouseOver={() => onLabelHover(3)}
        onMouseLeave={resetLabelHover}
      ></Label>
      <Label
        position="right"
        animate={startAnimation}
        onMouseOver={() => onLabelHover(1)}
        onMouseLeave={resetLabelHover}
      ></Label>
      <Label
        position="bottom"
        animate={startAnimation}
        onMouseOver={() => onLabelHover(2)}
        onMouseLeave={resetLabelHover}
      ></Label>
    </Container>
  );
};
