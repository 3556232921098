import React from "react";
import styled from "styled-components";
import { SCREEN_SIZES } from "../../styledComponents/screenSizes";

const Content = styled.div`
  display: grid;
  grid-template-columns: ${({ columns }) => Array(columns + 1).join("1fr ")};
  grid-gap: 8px;

  @media only screen and (max-width: ${SCREEN_SIZES.tablet}) {
    grid-template-columns: ${({ columnsTablet }) =>
      Array(columnsTablet + 1).join("1fr ")};
  }

  @media only screen and (max-width: ${SCREEN_SIZES.mobile}) {
    grid-template-columns: ${({ columnsMobile }) =>
      Array(columnsMobile + 1).join("1fr ")};
  }
`;

const Image = styled.img`
  width: 100%;
  height: auto;
`;

export const ColumnImages = ({
  images,
  columns,
  columnsTablet,
  columnsMobile,
}) => {
  return (
    <Content
      columns={columns}
      columnsTablet={columnsTablet || 1}
      columnsMobile={columnsMobile || 1}
    >
      {images?.map((item, index) => (
        <Image key={index} src={item.path} alt={item.alt} />
      ))}
    </Content>
  );
};
