import React from "react";
import { FooterSection } from "../../components/footerSection";
import { LandingIntro } from "./landingIntro";
import { PartnersList } from "./partnersList";
import { ServicesList } from "./servicesList";
import { HeaderSection } from "../../components/headerSection";
import { MetaDecorator } from "../../utils/metaDecorator";
import { useTranslation } from "react-i18next";

export const Services = () => {
  const { t } = useTranslation();

  return (
    <>
      <MetaDecorator
        title={t("metaTags.servicesTitle")}
        description={t("metaTags.servicesDescription")}
        type="website"
        alt="Spring services"
      />
      <HeaderSection color="dark" />
      <LandingIntro />
      <ServicesList />
      <PartnersList />
      <FooterSection />
    </>
  );
};
