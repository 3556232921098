import React from "react";
import { FooterSection } from "../../components/footerSection";
import { HeaderSection } from "../../components/headerSection";
import { MetaDecorator } from "../../utils/metaDecorator";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { COLORS } from "../../styledComponents/colors";

const NotFoundContainer = styled.div`
  min-height: calc(100vh - 650px);
  color: ${COLORS.darkBlue};
  font-size: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 50px;
  padding: 190px 50px 50px 50px;
`;

export const NotFound = () => {
  const { t } = useTranslation();

  return (
    <>
      <MetaDecorator
        title={t("metaTags.homepageAlt")}
        description={t("metaTags.companyDescription")}
        type="website"
        alt="Spring Communications"
      />
      <HeaderSection color="dark" />
      <NotFoundContainer>404 Not Found</NotFoundContainer>
      <FooterSection />
    </>
  );
};
