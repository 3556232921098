import React, { useRef, useEffect } from "react";
import styled from "styled-components";
import { COLORS } from "../../styledComponents/colors";
import { SCREEN_SIZES } from "../../styledComponents/screenSizes";

const Wrapper = styled.div`
  margin: 0 auto;
`;

const Title = styled.h1`
  margin-top: 5px;
  font-family: "GothamBold";
  color: ${COLORS.darkBlue};
  font-size: 1.5rem;

  @media only screen and (max-width: ${SCREEN_SIZES.mobile}) {
    font-size: 1.2rem;
  }
`;

const Content = styled.p`
  font-family: "GothamBook";
  font-size: 1.25rem;
  margin-top: 15px;
  min-width: 140px;
  max-width: 1000px;
  color: ${COLORS.darkBlue};

  @media only screen and (max-width: ${SCREEN_SIZES.mobile}) {
    font-size: 1rem;
  }
`;

export const Item = ({ item, index, scrollTo }) => {
  const itemRef = useRef(null);

  useEffect(() => {
    if (scrollTo) {
      window.scrollTo({ top: itemRef.current.offsetTop, behavior: "smooth" });
    }
  }, [itemRef, scrollTo]);

  return (
    <li key={item.slug} ref={itemRef}>
      <Wrapper>
        <Title>{item.title}</Title>
        <Content>{item.description}</Content>
      </Wrapper>
    </li>
  );
};
