export const COLORS = {
  black: "#0B172B",
  darkBlue: "#13233E",
  darkerBlue: "#0D1727",
  blue: "#16639B",
  lightBlue: "#2A6FA1",
  lighterBlue: "#1b3154",
  white: "#ffffff",
  darkGrey: "#393435",
  grey: "#808084",
  lightGrey: "#919295",
  lighterGrey: "#f7f7f7",
  extraLighterGray: "#b2b2b2",
};
